import React, { useState } from 'react'
import { View, StyleSheet, ScrollView, Image } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import logoImage from '~/assets/images/jan_logo2x-03.png'
import RegistrationCompleteColumn from '~/components/common/features/registration/RegistrationComplete'
import RegistrationJobHuntingColumn from '~/components/common/features/registration/RegistrationJobHuntingColumn'
import RegistrationLeftColumn from '~/components/common/features/registration/RegistrationLeftColumn'
import RegistrationProfileColumn from '~/components/common/features/registration/RegistrationProfileColumn'
import AuthenticationHeaderRight from '~/components/common/molecules/headers/AuthenticationHeaderRight'
import color from '~/constants/common/color'
import Profile from '~/interfaces/Profile'
import { RootState } from '~/rootReducer'
import { createCurrentUser } from '~/slices/common/users'

export type RegistrationTemplateHook = {
  registrationData: Partial<Profile>
  setRegistrationData: (registrationData: Partial<Profile>) => void
  currentColumnIndex: number
  setCurrentColumnIndex: (columnIndex: number) => void
}

export const useRegistrationTemplate = (
  defaultRegistrationData?: Partial<Profile>
): RegistrationTemplateHook => {
  const [registrationData, setRegistrationData] = useState(
    defaultRegistrationData || {}
  )
  const [currentColumnIndex, setCurrentColumnIndex] = useState(0)
  return {
    registrationData,
    setRegistrationData,
    currentColumnIndex,
    setCurrentColumnIndex,
  }
}

type Props = {
  defaultRegistrationData?: Partial<Profile>
}

const RegistrationTemplate: React.FC<Props> = ({
  defaultRegistrationData,
}: Props) => {
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const dispatch = useDispatch()
  const isRegistering = useSelector((state: RootState) => state.users.isLoading)
  const {
    registrationData,
    setRegistrationData,
    currentColumnIndex,
    setCurrentColumnIndex,
  } = useRegistrationTemplate(defaultRegistrationData)
  const registrationContainerStyle = isPC ? styles.pc : styles.mobile
  const registrationStyle = isPC
    ? styles.registration
    : styles.registrationMobile
  const switchLanguageStyle = isPC
    ? styles.switchLanguage
    : styles.switchLanguageMobile

  const handleChangeRegistration = (data: Partial<Profile>): void => {
    setRegistrationData(Object.assign(registrationData, data))
  }

  const nextColumn = (): void => {
    setCurrentColumnIndex(currentColumnIndex + 1)
  }

  const previousColumn = (): void => {
    setCurrentColumnIndex(currentColumnIndex - 1)
  }

  const submitRegistration = async (): Promise<void> => {
    try {
      const submissionData = {
        userProfile: registrationData,
      }
      await Promise.all([dispatch(createCurrentUser(submissionData))])
    } catch (error) {
      //TODO error
    }
  }

  const registrationChildColumns: React.ReactElement[] = [
    <RegistrationProfileColumn
      key="0"
      onUpdateRegistrationData={handleChangeRegistration}
      profileData={registrationData}
      onNextColumn={nextColumn}
    />,
    <RegistrationJobHuntingColumn
      key="1"
      onUpdateJobHuntingData={handleChangeRegistration}
      jobHuntingData={registrationData}
      onPreviousColumn={previousColumn}
      onNextColumn={nextColumn}
    />,
    <RegistrationCompleteColumn
      onSubmit={submitRegistration}
      key="2"
      isRegistering={isRegistering}
    />,
  ]

  return (
    <ScrollView
      contentContainerStyle={isPC ? styles.contentContainerStyle : undefined}
      testID="registration-template"
    >
      <View style={registrationContainerStyle} testID="registration-container">
        <View style={switchLanguageStyle}>
          {!isPC && <Image source={logoImage} style={styles.logo} />}
          <View style={styles.buttonLanguage}>
            <AuthenticationHeaderRight />
          </View>
        </View>
        {isPC && <RegistrationLeftColumn />}
        <View style={registrationStyle} testID="registration-column">
          {registrationChildColumns[currentColumnIndex]}
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  buttonLanguage: {
    marginLeft: 'auto',
    paddingTop: 5,
  },
  contentContainerStyle: {
    flex: 1,
  },
  logo: {
    height: 40,
    marginLeft: 20,
    marginRight: 30,
    marginTop: 5,
    width: 120,
  },
  mobile: {
    backgroundColor: color.white,
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    minHeight: '100vh',
    overflow: 'visible',
    paddingTop: 15,
  },
  pc: {
    flex: 1,
  },
  registration: {
    height: '100%',
    justifyContent: 'center',
    left: '30%',
    position: 'absolute',
    width: '100%',
  },
  registrationMobile: {
    flex: 14,
  },
  switchLanguage: {
    position: 'absolute',
    right: 0,
    margin: 45,
    zIndex: 1,
  },
  switchLanguageMobile: {
    flex: 1,
    flexDirection: 'row',
  },
})

export default RegistrationTemplate
