import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'

import FacebookIcon from '~/components/common/atoms/FacebookIcon'
import InstagramIcon from '~/components/common/atoms/InstagramIcon'
import LinkedInIcon from '~/components/common/atoms/LinkedInIcon'
import Logo from '~/components/common/molecules/Logo'
import color from '~/constants/common/color'
import { companyAddress } from '~/constants/landingPage/CompanyInformation'
import useDevice from '~/hooks/commons/useDevice'
import { navigate } from '~/utils/navigation'

const Footer = () => {
  const { isPC, isMobile } = useDevice()
  const { t } = useTranslation()
  const links = [
    { key: 'about', label: t('footer.menu.aboutUs') },
    { key: 'faq', label: t('footer.menu.faq') },
    {
      key: 'privacyPolicy',
      label: t('footer.menu.privacyPolicy'),
      onPress: () => navigate('privacy'),
    },
    {
      key: 'termsOfUse',
      label: t('footer.menu.termsOfUse'),
      onPress: () => navigate('terms'),
    },
  ]

  const contactInfo = [
    {
      key: 'contact',
      style: styles.sectionTitle,
      text: t('footer.addressTitle'),
    },
    { key: 'office', style: styles.boldText, text: t('footer.tokyoOffice') },
    { key: 'address', style: styles.address, text: companyAddress },
  ]

  const socialIcons = [
    { key: 'facebook', Icon: FacebookIcon },
    { key: 'linkedin', Icon: LinkedInIcon },
    { key: 'instagram', Icon: InstagramIcon },
  ]

  const mobileSections = [
    !isPC && styles.mobileSection,
    isMobile && {
      display: 'none',
    },
  ] as ViewStyle

  return (
    <View style={[styles.container, !isPC && styles.mobileContainer]}>
      {/* Logo */}
      <Logo isHideImage inverted size="xLarge" />

      {/* Company information */}
      <View style={mobileSections}>
        <Text style={styles.sectionTitle}>{t('janInc')}</Text>
        {links.map(({ key, label, onPress }) => (
          <TouchableOpacity key={key} onPress={onPress}>
            <Text style={styles.link} testID={key}>
              {label}
            </Text>
          </TouchableOpacity>
        ))}
      </View>

      {/* Contact */}
      <View style={mobileSections}>
        {contactInfo.map(({ key, style, text }) => (
          <Text
            key={key}
            testID={key}
            style={[style, !isPC && styles.textAlignCenter]}
          >
            {text}
          </Text>
        ))}
      </View>

      {/* Social media icon */}
      <View style={[styles.socialIcons, !isPC && styles.mobileSocialIcons]}>
        {socialIcons.map(({ key, Icon }) => (
          <TouchableOpacity key={key}>
            <Icon height={34} width={34} />
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  address: {
    fontSize: 18,
    color: color.white,
    lineHeight: 25,
  },
  boldText: {
    fontSize: 19,
    color: color.white,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: color.primary,
    paddingVertical: 91,
    paddingHorizontal: '10%',
    gap: 50,
  },
  link: {
    fontSize: 14,
    color: color.white,
    marginBottom: 6,
  },
  mobileContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 40,
    gap: 20,
  },
  mobileSection: {
    alignItems: 'center',
    marginBottom: 20,
  },
  mobileSocialIcons: {
    flexDirection: 'row',
    gap: 20,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: color.white,
    marginBottom: 8,
  },
  socialIcons: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
})

export default Footer
