import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

import Logo from '~/components/common/molecules/Logo'
import LandingPageMobileMenu from '~/components/common/organisms/LandingPageMobileMenu'
import color from '~/constants/common/color'
import {
  getNavigationMenu,
  NavigationTab,
} from '~/constants/landingPage/Navigation'
import useDevice from '~/hooks/commons/useDevice'
import { RootStackParamList } from '~/interfaces/RootStackParamList'
import { RootState } from '~/rootReducer'
import { logout as resetAccessToken } from '~/slices/common/auth'
import { reset as resetNotification } from '~/slices/common/notifications'
import { navigate } from '~/utils/navigation'

const LandingPageHeader = () => {
  const { isPC } = useDevice()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigation = useNavigation()
  const route = useRoute<RouteProp<RootStackParamList, 'topPage'>>()

  const activeTab = route.params?.tab || null
  const { t } = useTranslation()
  const navigationMenu = getNavigationMenu(t)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const dispatch = useDispatch()

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const redirectToLoginOrRegister = (screen: 'login' | 'signup') => {
    navigate(screen)
    toggleMenu()
  }

  const handleTabChange = (
    tabName: NavigationTab,
    selectedSubmenuIndex?: number
  ) => {
    navigation.setParams({
      tab: tabName,
      selectedSubmenuIndex: selectedSubmenuIndex,
    } as never)

    if (!isPC) {
      toggleMenu()
    }
  }

  const handleLogout = () => {
    Promise.all([
      dispatch(resetAccessToken()),
      dispatch(resetNotification()),
    ]).then(() => {
      navigate('login')
      toggleMenu()
    })
  }

  const menuIconStyle = [
    styles.menuIcon,
    { color: isMenuOpen ? color.white : color.outerSpace },
  ]

  return (
    <>
      <View style={styles.headerContainer}>
        {/* Logo */}
        <Logo isHideText />

        {/* Navigation Links */}
        {isPC ? (
          navigationMenu.map((item, index) => (
            <TouchableOpacity
              onPress={() => handleTabChange(item.link)}
              key={index}
              style={[
                styles.navItem,
                activeTab === item.link && styles.activeTab,
                { display: item.display },
              ]}
            >
              <Text style={styles.navText} testID={item.link}>
                {item.name}
              </Text>
            </TouchableOpacity>
          ))
        ) : (
          <TouchableOpacity
            onPress={toggleMenu}
            testID="menu-button"
            style={styles.menuButton}
          >
            <Text style={menuIconStyle}>☰</Text>
          </TouchableOpacity>
        )}

        {/* Login and Register */}
        {isPC ? (
          accessToken === undefined ? (
            <View style={styles.authContainer}>
              <TouchableOpacity
                onPress={() => redirectToLoginOrRegister('login')}
              >
                <Text style={styles.authText} testID="login">
                  {t('auth.login')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.registerButton}
                onPress={() => redirectToLoginOrRegister('signup')}
              >
                <Text style={styles.registerText} testID="register">
                  {t('auth.register')}
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.authContainer}>
              <TouchableOpacity
                style={styles.logoutButton}
                onPress={handleLogout}
              >
                <Text style={styles.registerText} testID="logout">
                  {t('auth.logout')}
                </Text>
              </TouchableOpacity>
            </View>
          )
        ) : null}
      </View>

      {/* Mobile Menu */}
      {!isPC && (
        <LandingPageMobileMenu
          redirectToLoginOrRegister={redirectToLoginOrRegister}
          handleLogout={handleLogout}
          onPressItem={handleTabChange}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  activeTab: {
    borderBottomColor: color.primary,
  },
  authContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    gap: 20,
  },
  authText: {
    fontSize: 16,
    color: color.textGray,
    marginRight: 10,
    fontWeight: 'bold',
  },
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 10,
    backgroundColor: color.white,
    borderBottomWidth: 1,
    borderBottomColor: color.gray,
    maxWidth: 1920,
    alignSelf: 'center',
  },
  logoutButton: {
    backgroundColor: color.danger,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5,
  },
  menuButton: {
    padding: 10,
  },
  menuIcon: {
    fontSize: 30,
    color: color.black,
  },
  navItem: {
    marginHorizontal: 20,
    paddingVertical: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: color.textGray,
    borderBottomWidth: 3,
    borderTopWidth: 3,
    borderBottomColor: color.transparent,
    borderTopColor: color.transparent,
  },
  navText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: color.textGray,
  },
  registerButton: {
    backgroundColor: color.primary,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 2,
  },
  registerText: {
    fontSize: 16,
    color: color.white,
    fontWeight: 'bold',
  },
})

export default LandingPageHeader
