import { TFunction } from 'i18next'

import useDevice from '~/hooks/commons/useDevice'

export enum NavigationTab {
  COMMUNITY = 'community',
  PROGRAM = 'program',
  FIND = 'find',
  HOME = 'topPage',
}

export type MenuItemType = {
  name: string
  link: NavigationTab
  icon: string
  submenu?: { title: string; content: string }[]
  display: 'flex' | 'none'
}

export const getSubmenuList = (t: TFunction, tab: NavigationTab) => {
  switch (tab) {
    case NavigationTab.COMMUNITY:
      return [
        {
          title: t('landing.ourCommunityMenuSidebar.communityOverview'),
          content: t(
            'landing.ourCommunityMenuSidebar.communityOverViewContentMobile'
          ),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.myProfile'),
          content: t('landing.ourCommunityMenuSidebar.myProfileContent'),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.messageBoard'),
          content: t('landing.ourCommunityMenuSidebar.messageBoardContent'),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.alumniClassNote'),
          content: t('landing.ourCommunityMenuSidebar.alumniClassNotesContent'),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.stories'),
          content: t('landing.ourCommunityMenuSidebar.storiesContent'),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.careerAndNetworking'),
          content: t(
            'landing.ourCommunityMenuSidebar.careerAndNetworkingContent'
          ),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.alumniServices'),
          content: t('landing.ourCommunityMenuSidebar.alumniServicesContent'),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.aboutJAN'),
          content: t('landing.ourCommunityMenuSidebar.aboutJANContent'),
        },
        {
          title: t('landing.ourCommunityMenuSidebar.help'),
          content: t('landing.ourCommunityMenuSidebar.helpContent'),
        },
      ]
    case NavigationTab.PROGRAM:
      return [
        {
          title: t('landing.programsAndEvents.ourEvents'),
          content: t(
            'landing.programsAndEvents.programsAndEventsMenuSidebar.ourEventsContent'
          ),
        },
        {
          title: t(
            'landing.programsAndEvents.programsAndEventsMenuSidebar.featuredPrograms'
          ),
          content: t(
            'landing.programsAndEvents.programsAndEventsMenuSidebar.featuredProgramContent'
          ),
        },
        {
          title: t(
            'landing.programsAndEvents.programsAndEventsMenuSidebar.onlineLearning'
          ),
          content: t(
            'landing.programsAndEvents.programsAndEventsMenuSidebar.onlineLearningContent'
          ),
        },
      ]
    case NavigationTab.FIND:
      return []
    case NavigationTab.HOME:
      return []
    default:
      return []
  }
}

export const getNavigationMenu = (t: TFunction): MenuItemType[] => {
  const { isPC } = useDevice()
  return [
    {
      name: t('menu.home'),
      link: NavigationTab.HOME,
      icon: 'home',
      submenu: undefined,
      display: isPC ? 'none' : 'flex',
    },
    {
      name: t('landing.navigationMenu.community'),
      link: NavigationTab.COMMUNITY,
      icon: 'groups',
      submenu: getSubmenuList(t, NavigationTab.COMMUNITY),
      display: 'flex',
    },
    {
      name: t('landing.navigationMenu.programAndEvents'),
      link: NavigationTab.PROGRAM,
      icon: 'calendar-today',
      submenu: getSubmenuList(t, NavigationTab.PROGRAM),
      display: 'flex',
    },
    {
      name: t('landing.navigationMenu.find'),
      link: NavigationTab.FIND,
      icon: 'location-on',
      submenu: undefined,
      display: 'flex',
    },
  ]
}
