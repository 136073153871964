import React from 'react'
import { useTranslation } from 'react-i18next'

import Regions from '~/components/common//organisms/Regions'
import Footer from '~/components/common/organisms/Footer'
import LandingPageBanner from '~/components/common/organisms/LandingPageBanner'
import PairButtons from '~/components/common/organisms/button/PairButtons'

const Find = () => {
  const { t } = useTranslation()
  const [
    isWorldwideRegionSelected,
    setIsWorldwideRegionSelected,
  ] = React.useState<boolean>(false)

  const renderActionButtons = () => {
    return (
      <PairButtons
        items={[t('landing.find.japan'), t('landing.find.worldwide')]}
        onPress={(index) => setIsWorldwideRegionSelected(index === 1)}
      />
    )
  }

  return (
    <>
      <LandingPageBanner
        title={t('landing.find.title')}
        subTitle={t('landing.find.subtitle')}
        actionButtons={renderActionButtons()}
      />
      <Regions isWorldwideRegionSelected={isWorldwideRegionSelected} />
      <Footer />
    </>
  )
}

export default Find
