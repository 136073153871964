import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  Image,
  StyleSheet,
  ViewStyle,
  ImageStyle,
  TextStyle,
  useWindowDimensions,
} from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

interface Props {
  sections: { title: string; items: string[] }[]
}

const WhatWeDo = ({ sections }: Props) => {
  const { isPC, isMobile, isTablet } = useDevice()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const isSmallScreen = width < 920
  const isMediumScreen = width >= 920 && width < 1630

  const getFlexDirection = () => {
    if (isSmallScreen) return 'column-reverse'
    return 'row'
  }

  const flexDirection = getFlexDirection()

  const getFontSize = () => {
    if (isMobile) return { title: 30, detailTitle: 20, detailItem: 14 }
    if (isTablet) return { title: 50, detailTitle: 25, detailItem: 18 }
    if (isMediumScreen) return { title: 60, detailTitle: 30, detailItem: 20 }
    return { title: 70, detailTitle: 35, detailItem: 25 }
  }

  const fontSizes = getFontSize()

  const imageStyle: ImageStyle = {
    flex: isMobile || isSmallScreen ? undefined : 1,
    height: isPC && !isSmallScreen ? 995 : isTablet ? 600 : 400,
    width: isSmallScreen ? '100%' : undefined,
    resizeMode: 'contain',
    marginBottom: isSmallScreen ? 30 : 0,
    alignSelf: isSmallScreen ? 'center' : 'flex-start',
  }

  const titleTextStyle: TextStyle = {
    color: color.outerSpace,
    fontSize: fontSizes.title,
    textAlign: isSmallScreen ? 'center' : 'left',
    fontWeight: 'bold',
    marginBottom: isPC && !isSmallScreen ? 120 : 30,
  }

  const contentViewStyle: ViewStyle = {
    justifyContent: 'center',
    flex: 1,
    height: '100%',
    alignItems: isSmallScreen ? 'center' : 'flex-start',
    paddingHorizontal: isSmallScreen ? 20 : 0,
  }

  const containerStyle = {
    flexDirection,
    paddingVertical: isSmallScreen ? 50 : 126,
    paddingHorizontal: isSmallScreen ? 20 : isMediumScreen ? 50 : 258,
    gap: isPC && !isSmallScreen ? 93 : 30,
    width,
    alignItems: isSmallScreen ? 'center' : 'flex-start',
    justifyContent: isSmallScreen ? 'center' : 'space-between',
  } as ViewStyle

  const detailTitleStyle: TextStyle = {
    fontWeight: 'bold',
    color: color.outerSpace,
    marginBottom: 10,
    fontSize: fontSizes.detailTitle,
    textAlign: isSmallScreen ? 'center' : 'left',
  }

  const detailItemStyle: TextStyle = {
    color: color.outerSpace,
    marginRight: 10,
    marginBottom: 5,
    fontSize: fontSizes.detailItem,
    lineHeight: fontSizes.detailItem + 5,
  }

  const detailItemContainerStyle: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'flex-start',
  }

  const renderItem = (
    section: { title: string; items: string[] },
    index: number
  ) => (
    <View key={index} style={styles.detailSection}>
      <Text style={detailTitleStyle}>{section.title}</Text>
      {section.items.map((item, itemIndex) => (
        <View style={detailItemContainerStyle} key={`${itemIndex}-container`}>
          <Text
            style={detailItemStyle}
            testID={`detail-item-${index}-${itemIndex}`}
          >
            •
          </Text>
          <Text style={[detailItemStyle, styles.flex1]} key={itemIndex + 1}>
            {item}
          </Text>
        </View>
      ))}
    </View>
  )

  return (
    <View
      style={[styles.detailsContainer, containerStyle]}
      testID="intro-details-section"
    >
      <Image
        source={require('~/assets/images/landingPage/whatWeDo.png')}
        style={imageStyle}
      />
      <View style={contentViewStyle}>
        <TitleText h3 style={titleTextStyle}>
          {t('landing.oneTimeIntro.whatWeDo')}
        </TitleText>
        {sections.map((section, index) => renderItem(section, index))}
      </View>
    </View>
  )
}

export default WhatWeDo

const styles = StyleSheet.create({
  detailSection: {
    marginBottom: 25,
    flex: 1,
    width: '100%',
  },
  detailsContainer: {
    backgroundColor: color.white,
    alignItems: 'center',
    maxWidth: 1920,
  },
  flex1: {
    flex: 1,
  },
})
