import { TFunction } from 'i18next'

import color from '~/constants/common/color'
import { navigate } from '~/utils/navigation'

export const getInvolvedFeatures = (t: TFunction) => [
  {
    title: t('landing.involved.features.alumniDirectory'),
    description: t('landing.involved.features.alumniDirectoryDescription'),
    backgroundColor: color.americanSilver,
    onPress: () => navigate('communityMain'),
  },
  {
    title: t('landing.involved.features.benefitsAndResources'),
    description: t('landing.involved.features.benefitsAndResourcesDescription'),
    backgroundColor: color.pewterBlue,
    onPress: () => navigate('communityMain'),
  },
  {
    title: t('landing.involved.features.alumniCommunity'),
    description: t('landing.involved.features.alumniCommunityDescription'),
    backgroundColor: color.polishedPine,
    onPress: () => navigate('communityMain'),
  },
  {
    title: t('landing.involved.features.programsAndEvents'),
    description: t('landing.involved.features.programsAndEventsDescription'),
    backgroundColor: color.illuminatingEmerald,
    onPress: () => navigate('communityMain'),
  },
]
