import React, { useMemo } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import VideoThumbnail from 'react-video-thumbnail'

import videoPlayImage from '~/assets/images/video_play.png'
import PlayButtonIcon from '~/components/common/atoms/PlayButtonIcon'
import ProportionalImage from '~/components/common/atoms/ProportionalImage'

type Props = {
  video: boolean
  small: boolean
  uri: string
  ignoreCache: boolean
  onError?: () => void
  onLoad?: () => void
}

const wrapURI = (uri: string) =>
  `${uri}${uri.includes('?') ? '&' : '?'}_t=${new Date().getTime()}`

const MediaThumbnail: React.FC<Props> = ({
  video,
  small,
  uri,
  ignoreCache,
  onLoad,
  onError,
}: Props) => {
  let image: React.ReactElement
  uri = useMemo(() => (ignoreCache ? wrapURI(uri) : uri), [uri, ignoreCache])
  if (small) {
    image = (
      <Image
        testID="media-thumbnail-image"
        source={{ uri: uri }}
        style={styles.image}
        onLoad={onLoad}
        onError={onError}
      />
    )
  } else {
    image = (
      <ProportionalImage
        style={styles.container}
        uri={uri}
        stretch="vertical"
        onLoad={onLoad}
        onError={onError}
      />
    )
  }
  if (video) {
    const iconStyle = small ? styles.playSmallButton : styles.playNormalButton

    const iconSize = small ? 26 : 50
    return (
      <View style={styles.container}>
        {image}
        <VideoThumbnail
          videoUrl={uri}
          image={videoPlayImage}
          snapshotAtTime={1}
        />
        <View style={iconStyle} testID="media-thumbnail-video-play-icon">
          <PlayButtonIcon width={iconSize} height={iconSize} />
        </View>
      </View>
    )
  } else {
    return image
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
  },
  image: {
    flex: 1,
    maxHeight: 600,
  },
  playNormalButton: {
    alignItems: 'center',
    position: 'absolute',
    justifyContent: 'center',
    width: 50,
    height: 50,
  },
  playSmallButton: {
    height: 30,
    width: 26,
  },
})

export default MediaThumbnail
