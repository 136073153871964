import i18n, { TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'

import careerEn from '~/translations/career/career.en.json'
import careerJa from '~/translations/career/career.ja.json'
import commonEn from '~/translations/common/common.en.json'
import commonJa from '~/translations/common/common.ja.json'
import communityEn from '~/translations/community/community.en.json'
import communityJa from '~/translations/community/community.ja.json'
import forumEn from '~/translations/forum/forum.en.json'
import forumJa from '~/translations/forum/forum.ja.json'

export const languageStorageKey = 'language'
export enum SupportedLanguage {
  EN = 'en',
  JA = 'ja',
}

// the translations
// (tip move them in a JSON file and import them)
export const resources = {
  en: {
    translation: {
      ...commonEn,
      ...careerEn,
      ...forumEn,
      ...communityEn,
    },
  },
  ja: {
    translation: {
      ...commonJa,
      ...careerJa,
      ...forumJa,
      ...communityJa,
    },
  },
}

const defaultLanguage = window.navigator?.language
  ? window.navigator.language
  : SupportedLanguage.EN

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    fallbackLng: SupportedLanguage.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export const switchLanguage = (lang?: string): Promise<TFunction> => {
  if (lang) return i18n.changeLanguage(lang)
  const currentLanguage =
    i18n.language === SupportedLanguage.JA
      ? SupportedLanguage.EN
      : SupportedLanguage.JA
  return i18n.changeLanguage(currentLanguage)
}

export const getCurrentLanguage = (): SupportedLanguage => {
  return i18n.language === SupportedLanguage.JA
    ? SupportedLanguage.JA
    : SupportedLanguage.EN
}

export default i18n
