import React from 'react'
import {
  View,
  Image,
  StyleSheet,
  Text,
  useWindowDimensions,
  ImageStyle,
  TextStyle,
  ViewStyle,
} from 'react-native'

import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

const BASE_SIZES = {
  pc: { imageHeight: 200, fontSize: 24, numColumns: 4 },
  tablet: { imageHeight: 140, fontSize: 16, numColumns: 3 },
  mobile: { imageHeight: 60, fontSize: 12, numColumns: 2 },
}

const BASE_GAPS = {
  pc: 20,
  tablet: 15,
  mobile: 10,
}

interface Props {
  communityImages: { title: string; image: number }[]
}

const IntroCommunitiesSection = ({ communityImages }: Props) => {
  const { isTablet, isMobile } = useDevice()
  const { width } = useWindowDimensions()

  const scaleFactor = width < 1920 ? Math.max(width / 1920, 0.5) : 1

  const sizes = isMobile
    ? {
        imageHeight: BASE_SIZES.mobile.imageHeight,
        fontSize: BASE_SIZES.mobile.fontSize,
      }
    : isTablet
    ? {
        imageHeight: BASE_SIZES.tablet.imageHeight,
        fontSize: BASE_SIZES.tablet.fontSize,
      }
    : {
        imageHeight: BASE_SIZES.pc.imageHeight * scaleFactor,
        fontSize: BASE_SIZES.pc.fontSize * scaleFactor,
      }

  const numColumns = isMobile
    ? BASE_SIZES.mobile.numColumns
    : isTablet
    ? BASE_SIZES.tablet.numColumns
    : BASE_SIZES.pc.numColumns

  const gap = isMobile
    ? BASE_GAPS.mobile
    : isTablet
    ? BASE_GAPS.tablet
    : BASE_GAPS.pc * scaleFactor
  const padding = isMobile ? 5 : isTablet ? 10 : 20 * scaleFactor

  const containerStyle = {
    padding,
    flex: 1,
    width,
    gap,
    alignItems: 'center',
    maxWidth: 1920,
  } as ViewStyle

  const rowStyle = {
    flexDirection: 'row',
    justifyContent: numColumns === 2 ? 'center' : 'space-between',
    gap,
    marginBottom: gap,
    flexWrap: 'wrap',
  } as ViewStyle

  const itemContainerStyle = {
    paddingHorizontal: gap,
    paddingVertical: padding,
    width,
    maxWidth: 1920,
  }

  const renderCommunityItem = (
    item: { title: string; image: number },
    index: number
  ) => {
    const textStyle = {
      fontSize: sizes.fontSize * scaleFactor,
      color: color.outerSpace,
      textAlign: 'center',
      paddingVertical: isMobile ? 5 : 10,
      width: '100%',
      fontWeight: 'bold',
      alignSelf: 'center',
      lineHeight: sizes.fontSize + 5,
    } as TextStyle

    const imageStyle = {
      width: '100%',
      height: sizes.imageHeight,
      resizeMode: 'cover',
    } as ImageStyle

    return (
      <View
        key={index}
        style={[styles.itemContainer, { flex: 1 / numColumns }]}
        testID={`community-item-${index}`}
      >
        <Image
          source={item.image}
          style={imageStyle}
          testID={`community-image-${index}`}
        />
        <Text style={textStyle}>{item.title}</Text>
      </View>
    )
  }

  const rows: { title: string; image: number }[][] = []
  for (let i = 0; i < communityImages.length; i += numColumns) {
    rows.push(communityImages.slice(i, i + numColumns))
  }

  return (
    <View style={containerStyle} testID="intro-communities-section">
      <View style={itemContainerStyle}>
        {rows.map((row, rowIndex) => (
          <View key={rowIndex} style={rowStyle}>
            {row.map((item, itemIndex) =>
              renderCommunityItem(item, rowIndex * numColumns + itemIndex)
            )}
            {row.length < numColumns &&
              Array.from({
                length: numColumns - row.length,
              }).map((_, index) => (
                <View
                  key={`empty-${rowIndex}-${index}`}
                  style={[{ flex: 1 / numColumns }, styles.margin5]}
                />
              ))}
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    elevation: 4,
    shadowColor: color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    backgroundColor: color.white,
    margin: 5,
  },
  margin5: { margin: 5 },
})

export default IntroCommunitiesSection
