import { TFunction } from 'i18next'

export const getMenuItemsOfCommunityDetails = (t: TFunction) => [
  {
    title: t('landing.ourCommunityMenuSidebar.communityOverview'),
    content: t('landing.ourCommunityMenuSidebar.communityOverViewContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.myProfile'),
    content: t('landing.ourCommunityMenuSidebar.myProfileContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.messageBoard'),
    content: t('landing.ourCommunityMenuSidebar.messageBoardContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.alumniClassNote'),
    content: t('landing.ourCommunityMenuSidebar.alumniClassNotesContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.stories'),
    content: t('landing.ourCommunityMenuSidebar.storiesContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.careerAndNetworking'),
    content: t('landing.ourCommunityMenuSidebar.careerAndNetworkingContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.alumniServices'),
    content: t('landing.ourCommunityMenuSidebar.alumniServicesContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.aboutJAN'),
    content: t('landing.ourCommunityMenuSidebar.aboutJANContent'),
  },
  {
    title: t('landing.ourCommunityMenuSidebar.help'),
    content: t('landing.ourCommunityMenuSidebar.helpContent'),
  },
]
