import React, { ReactElement } from 'react'
import { StyleSheet, View, Text, ViewStyle } from 'react-native'

import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

interface Props {
  title: string
  subTitle: string
  actionButtons?: ReactElement
  inverted?: boolean
  backgroundColor?: string
  isTitleColorPrimary?: boolean
}

const LandingPageBanner = ({
  title,
  subTitle,
  actionButtons,
  inverted,
  backgroundColor,
  isTitleColorPrimary = false,
}: Props) => {
  const { isPC, isMobile, isTablet } = useDevice()

  const containerStyle = [
    styles.container,
    isMobile && {
      paddingVertical: 30,
    },
    inverted &&
      isPC && {
        backgroundColor: backgroundColor ?? color.lightGray,
        paddingVertical: 222,
      },
    inverted &&
      !isPC && {
        backgroundColor: backgroundColor ?? color.lightGray,
        paddingVertical: 46,
      },
  ] as ViewStyle

  const bannerTitleStyle = [
    styles.bannerTitle,
    isMobile && styles.bannerTitleMobile,
    inverted &&
      isPC && {
        color: color.outerSpace,
        fontSize: 78,
      },
    inverted &&
      !isPC && {
        color: isTitleColorPrimary ? color.primary : color.outerSpace,
        marginBottom: 30,
        marginHorizontal: 20,
        marginTop: 10,
        lineHeight: 28,
        fontSize: 30,
      },
  ]

  const bannerSubtitleStyle = [
    styles.headerSubtitle,
    isPC || isTablet ? styles.headerSubtitlePC : styles.headerSubtitleMobile,
    inverted &&
      isPC && {
        color: color.outerSpace,
        fontSize: 32,
        lineHeight: 40,
        marginBottom: 65,
        marginTop: 42,
      },
    inverted &&
      !isPC && {
        color: color.outerSpace,
        maxWidth: '60%',
      },
  ]

  return (
    <View style={containerStyle} testID="landing-page-banner">
      <Text style={bannerTitleStyle} testID="banner-title">
        {title}
      </Text>
      <Text style={bannerSubtitleStyle} testID="banner-subtitle">
        {subTitle}
      </Text>
      {actionButtons && <View>{actionButtons}</View>}
    </View>
  )
}

const styles = StyleSheet.create({
  bannerTitle: {
    fontSize: 50,
    fontWeight: 'bold',
    color: color.white,
    marginBottom: 15,
  },
  bannerTitleMobile: {
    fontSize: 26,
    lineHeight: 19.2,
    letterSpacing: 0.75,
    textAlign: 'center',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: color.primary,
    paddingVertical: 80,
    maxWidth: 1920,
  },
  headerSubtitle: {
    fontSize: 16,
    color: color.white,
    textAlign: 'center',
    marginBottom: 25,
    lineHeight: 24,
  },
  headerSubtitleMobile: {
    maxWidth: '80%',
    fontSize: 13,
    lineHeight: 16,
  },
  headerSubtitlePC: {
    maxWidth: '50%',
    fontSize: 14,
  },
})

export default LandingPageBanner
