import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'

import color from '~/constants/common/color'
import { MenuItemType, NavigationTab } from '~/constants/landingPage/Navigation'

interface Props {
  item: MenuItemType
  selectedTab?: string
  expandedSubmenu?: string | null
  selectedSubmenuIndex?: number
  onPressItem: (tabName: NavigationTab, selectedSubmenuIndex?: number) => void
  onPress: (menuItem: MenuItemType) => void
}

const TopTabMenuItem: React.FC<Props> = ({
  item,
  selectedTab,
  expandedSubmenu,
  selectedSubmenuIndex,
  onPressItem,
  onPress,
}) => {
  const isActive = selectedTab === item.link
  const isOpen = expandedSubmenu === item.name

  const renderSubmenu = () => {
    if (!isOpen || !item.submenu) return null

    return (
      <View
        style={styles.subtitleContainer}
        testID={`submenu-container-${item.link}`}
      >
        {item.submenu.map((subtitleItem, index) => (
          <Text
            key={index}
            style={[
              styles.subtitleText,
              {
                color:
                  selectedSubmenuIndex === index
                    ? color.primary
                    : color.darkGray,
              },
            ]}
            onPress={() => onPressItem(item.link, index)}
            testID={`submenu-item-${item.link}-${index}`}
          >
            {subtitleItem.title}
          </Text>
        ))}
      </View>
    )
  }

  return (
    <View testID="top-tab-menu-item">
      <TouchableOpacity
        onPress={() => onPress(item)}
        style={styles.menuItem}
        testID={`menu-item-${item.link}`}
      >
        <MaterialIcons
          name={item.icon}
          size={25}
          color={isActive ? color.primary : color.outerSpace}
        />
        <Text
          style={[styles.mobileNavText, isActive && { color: color.primary }]}
          testID={`menu-text-${item.link}`}
        >
          {item.name}
        </Text>
      </TouchableOpacity>
      {renderSubmenu()}
    </View>
  )
}

export default TopTabMenuItem

const styles = StyleSheet.create({
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    gap: 23.35,
  },
  mobileNavText: {
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0.46,
    marginVertical: 10,
  },
  subtitleContainer: {
    borderRadius: 6,
    paddingLeft: 70,
    gap: 10,
    marginVertical: 10,
  },
  subtitleText: {
    fontSize: 14,
    color: color.darkGray,
  },
})
