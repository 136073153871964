import React, { useState } from 'react'
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'

import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

interface Props {
  data: {
    title: string
    content: string
  }[]
}

const SidebarLayout = ({ data }: Props) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const { isPC } = useDevice()

  const containerStyle = [
    styles.container,
    !isPC && {
      flexDirection: 'column',
      padding: 20,
      gap: 20,
      alignItems: 'center',
    },
  ] as ViewStyle

  const menuSectionStyle = [
    styles.menuSection,
    !isPC && {
      width: '100%',
      marginBottom: 20,
      paddingHorizontal: 20,
      paddingVertical: 10,
      flex: undefined,
    },
  ]

  const contentSectionStyle = [
    styles.contentSection,
    !isPC && {
      width: '100%',
      paddingHorizontal: 20,
      flex: undefined,
    },
  ]

  const renderItem = (
    item: {
      title: string
      content: string
    },
    index: number
  ) => (
    <TouchableOpacity
      key={index}
      style={[styles.menuItem, selectedItem === index && styles.activeMenuItem]}
      onPress={() => setSelectedItem(index)}
    >
      <Text
        testID="menu-sidebar-item"
        style={[
          styles.menuItemText,
          selectedItem === index && styles.activeMenuItemText,
        ]}
      >
        {item.title}
      </Text>
    </TouchableOpacity>
  )

  return (
    <View style={containerStyle}>
      <View style={menuSectionStyle}>
        {data.map((item, index) => renderItem(item, index))}
      </View>

      <View style={contentSectionStyle}>
        <Text style={styles.contentText} testID="item-content">
          {data[selectedItem].content}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  activeMenuItem: {
    paddingVertical: 10,
  },
  activeMenuItemText: {
    color: color.primary,
    fontSize: 20,
    fontWeight: 'bold',
  },
  container: {
    flexDirection: 'row',
    backgroundColor: color.lightGray,
    gap: 50,
    padding: 80,
    justifyContent: 'space-between',
  },
  contentSection: {
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentText: {
    fontSize: 18,
    lineHeight: 24,
    color: color.steelGray,
    marginBottom: 15,
  },
  menuItem: {
    paddingVertical: 10,
  },
  menuItemText: {
    fontSize: 20,
    color: color.steelGray,
  },
  menuSection: {
    backgroundColor: color.white,
    paddingVertical: 15,
    paddingHorizontal: 40,
    shadowColor: color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    alignSelf: 'baseline',
    flex: 1.5,
  },
})

export default SidebarLayout
