import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  ViewStyle,
} from 'react-native'
import AntDesignIcon from 'react-native-vector-icons/AntDesign'

import color from '~/constants/common/color'
import { getRegions, getCategories } from '~/constants/landingPage/Find'
import useDevice from '~/hooks/commons/useDevice'

type Region = {
  name: string
  image: number
}

interface Props {
  isWorldwideRegionSelected: boolean
}

const Regions = ({ isWorldwideRegionSelected }: Props) => {
  const { t } = useTranslation()
  const { isPC, isTablet, isMobile } = useDevice()

  const regions = getRegions(t, isWorldwideRegionSelected)
  const categories = getCategories(t)

  const midIndex = Math.ceil(categories.length / 2)
  const leftColumn = categories.slice(0, midIndex)
  const rightColumn = categories.slice(midIndex)

  const [selectedRegion, setSelectedRegion] = React.useState<null | Region>(
    null
  )

  const handleRegionSelect = (region: Region) => {
    setSelectedRegion(region)
  }

  React.useEffect(() => {
    setSelectedRegion(null)
  }, [isWorldwideRegionSelected])

  const getImage = () => {
    if (selectedRegion === null) {
      if (isWorldwideRegionSelected === false)
        return require('~/assets/images/landingPage/JapanMap.png')
      else return require('~/assets/images/landingPage/WorldMap.png')
    }
    return selectedRegion.image
  }

  const regionsStyle = [
    styles.regionButton,
    isPC && isWorldwideRegionSelected && styles.regionButtonDynamic,
    !isPC && styles.regionButtonMobile,
  ] as ViewStyle

  const renderCategoryItem = (category: string, index: number) => (
    <View
      style={styles.categoryItem}
      key={index}
      testID={`category-item-${index}`}
    >
      <AntDesignIcon
        name="caretright"
        size={isPC || isTablet ? 24 : 18}
        color={color.sliverGray}
      />
      <Text style={styles.categoryText}>{category}</Text>
    </View>
  )

  return (
    <View
      style={[styles.container, !isPC && styles.containerMobile]}
      testID="regions"
    >
      <View
        style={[styles.buttonsRow, !isPC && styles.buttonsRowMobile]}
        testID="regions-buttons-row"
      >
        {regions.map((region, index) => (
          <TouchableOpacity
            key={index}
            style={regionsStyle}
            testID={`region-button-${index}`}
            onPress={() => handleRegionSelect(region)}
          >
            <Text style={styles.regionButtonText}>{region.name}</Text>
          </TouchableOpacity>
        ))}
      </View>

      <Image
        source={getImage()}
        style={[styles.mapImage, !isPC && styles.mapImageMobile]}
        resizeMode="contain"
        testID="map-image"
      />

      <View
        style={[
          styles.categoriesContainer,
          isMobile && styles.categoriesContainerMobile,
          isTablet && styles.categoriesContainerTablet,
        ]}
        testID="categories-container"
      >
        <View
          style={[
            styles.columnContainer,
            isMobile && styles.columnContainerMobile,
            isTablet && styles.columnContainerTablet,
          ]}
          testID="left-column"
        >
          {leftColumn.map((item, index) => renderCategoryItem(item, index))}
        </View>

        <View style={styles.divider} testID="divider" />

        <View
          style={[
            styles.columnContainer,
            isMobile && styles.columnContainerMobile,
            isTablet && styles.columnContainerTablet,
          ]}
          testID="right-column"
        >
          {rightColumn.map((item, index) =>
            renderCategoryItem(item, index + midIndex)
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonsRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 10,
    paddingHorizontal: '10%',
  },
  buttonsRowMobile: {
    paddingHorizontal: 0,
  },
  categoriesContainer: {
    paddingHorizontal: '10%',
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  categoriesContainerMobile: {
    flexDirection: 'column',
    paddingHorizontal: 0,
  },
  categoriesContainerTablet: {
    paddingHorizontal: 0,
  },
  categoryItem: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    paddingVertical: 5,
    marginBottom: 20,
  },
  categoryText: {
    fontSize: 16,
    color: color.steelGray,
    flexWrap: 'wrap',
  },
  columnContainer: {
    width: '30%',
  },
  columnContainerMobile: {
    width: '100%',
  },
  columnContainerTablet: {
    width: '35%',
  },
  container: {
    flex: 1,
    backgroundColor: color.white,
    paddingVertical: 80,
  },
  containerMobile: {
    paddingHorizontal: 10,
  },
  divider: {
    borderLeftWidth: 1,
    borderColor: color.sliverGray,
  },
  mapImage: {
    width: '80%',
    height: 800,
    marginVertical: 80,
    alignSelf: 'center',
  },
  mapImageMobile: {
    width: '100%',
    height: 400,
  },
  regionButton: {
    backgroundColor: color.secondLightGray,
    paddingVertical: 10,
    paddingHorizontal: 15,
    width: '22%',
    alignItems: 'center',
  },
  regionButtonDynamic: {
    width: '30%',
  },
  regionButtonMobile: {
    width: '45%',
    marginHorizontal: 5,
  },
  regionButtonText: {
    fontSize: 14,
    color: color.outerSpace,
    fontWeight: 'bold',
  },
})

export default Regions
