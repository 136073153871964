import React from 'react'
import Svg, { Path } from 'react-native-svg'

type Props = {
  width?: number
  height?: number
  color?: string
}

const PlayButtonIcon: React.FC<Props> = ({
  width = 50,
  height = 50,
  color = 'white',
}: Props) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path d="M8 5v14l11-7L8 5z" fill={color} />
  </Svg>
)

export default PlayButtonIcon
