import { TFunction } from 'i18next'

export const getUpcomingEvents = (t: TFunction) => [
  {
    date: new Date(),
    title: t(
      'landing.programsAndEvents.upcomingEventsList.japaneseCultureImmersion'
    ),
    description: t(
      'landing.programsAndEvents.upcomingEventsList.japaneseCultureImmersionContent'
    ),
  },
  {
    date: new Date(),
    title: t(
      'landing.programsAndEvents.upcomingEventsList.workLifeBalanceInJapan'
    ),
    description: t(
      'landing.programsAndEvents.upcomingEventsList.workLifeBalanceInJapanContent'
    ),
  },
  {
    date: new Date(),
    title: t(
      'landing.programsAndEvents.upcomingEventsList.japaneseLanguageLearning'
    ),
    description: t(
      'landing.programsAndEvents.upcomingEventsList.japaneseLanguageLearningContent'
    ),
  },
  {
    date: new Date(),
    title: t('landing.programsAndEvents.upcomingEventsList.careerPathsPanel'),
    description: t(
      'landing.programsAndEvents.upcomingEventsList.careerPathsPanelContent'
    ),
  },
]

export const getMenuItemsOfProgramsAndEvents = (t: TFunction) => [
  {
    title: t(
      'landing.programsAndEvents.programsAndEventsMenuSidebar.allEvents'
    ),
    content: t(
      'landing.programsAndEvents.programsAndEventsMenuSidebar.allEventsContent'
    ),
  },
  {
    title: t(
      'landing.programsAndEvents.programsAndEventsMenuSidebar.featuredPrograms'
    ),
    content: t(
      'landing.programsAndEvents.programsAndEventsMenuSidebar.featuredProgramContent'
    ),
  },
  {
    title: t(
      'landing.programsAndEvents.programsAndEventsMenuSidebar.onlineLearning'
    ),
    content: t(
      'landing.programsAndEvents.programsAndEventsMenuSidebar.onlineLearningContent'
    ),
  },
]
