import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  useWindowDimensions,
} from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import InfoCard from '~/components/common/molecules/InfoCard'
import LabeledTextInput from '~/components/common/molecules/LabeledTextInput'
import color from '~/constants/common/color'
import { getUpcomingEvents } from '~/constants/landingPage/ProgramsAndEvents'
import useDevice from '~/hooks/commons/useDevice'

const UpcomingEvents: React.FC = () => {
  const { t } = useTranslation()
  const { isPC, isMobile, getDeviceType } = useDevice()
  const upcomingEvents = useMemo(() => getUpcomingEvents(t), [t])
  const { width } = useWindowDimensions()

  const FilterSection = () => (
    <View
      style={[
        styles.filterSection,
        !isPC && styles.filterSectionMobile,
        isMobile && styles.filterSectionHidden,
      ]}
      testID="filter-section"
    >
      <LabeledTextInput
        title={t('search.search')}
        inputStyle={styles.filterInput}
        testID="search-input"
      />
      <LabeledTextInput
        title={t('community.information.type')}
        inputStyle={styles.filterInput}
        testID="type-input"
      />
      <TouchableOpacity style={styles.filterButton} testID="apply-button">
        <Text style={styles.filterButtonText}>{t('apply')}</Text>
      </TouchableOpacity>
    </View>
  )

  return (
    <View
      style={[styles.layoutContainer, isPC && styles.layoutContainerPC]}
      testID="upcoming-events"
    >
      <View
        style={[styles.sideSpacer, isPC && styles.sideSpacerPC]}
        testID="side-spacer"
      />
      <View style={styles.mainContainer} testID="main-container">
        <TitleText
          style={[styles.sectionTitle, isMobile && styles.sectionTitleMobile]}
          testID="upcoming-events-title"
        >
          {t('landing.programsAndEvents.upcomingEvents')}
        </TitleText>

        <FilterSection />

        {upcomingEvents.map((event, index) => (
          <InfoCard
            key={index}
            event={event}
            index={index}
            deviceType={getDeviceType(width)}
          />
        ))}

        <TouchableOpacity
          style={[styles.seeAllButton, isMobile && styles.seeAllButtonVisible]}
          testID="see-all-button"
        >
          <Text style={styles.seeAllButtonText}>
            {t('landing.programsAndEvents.viewAllEvents')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export default UpcomingEvents

const styles = StyleSheet.create({
  filterButton: {
    backgroundColor: color.primary,
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
    alignItems: 'center',
    alignSelf: 'flex-end',
    borderWidth: 1,
    borderColor: color.primary,
  },
  filterButtonText: {
    color: color.white,
    fontWeight: 'bold',
    paddingHorizontal: 20,
  },
  filterInput: {
    flex: 2,
    borderWidth: 1,
    borderColor: color.borderSeparator,
    borderRadius: 8,
    padding: 10,
    backgroundColor: color.white,
  },
  filterSection: {
    flexDirection: 'row',
    paddingBottom: 40,
    gap: 10,
  },
  filterSectionHidden: {
    display: 'none',
  },
  filterSectionMobile: {
    flexDirection: 'column',
    gap: 20,
  },
  layoutContainer: {
    paddingHorizontal: 20,
  },
  layoutContainerPC: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 180,
  },
  mainContainer: {
    backgroundColor: color.lightGray,
    flex: 3,
  },
  sectionTitle: {
    fontSize: 40,
    marginBottom: 40,
    color: color.outerSpace,
  },
  sectionTitleMobile: {
    fontSize: 26,
    lineHeight: 30.2,
    letterSpacing: 0.75,
    textAlign: 'center',
  },
  seeAllButton: {
    borderRadius: 3,
    backgroundColor: color.primary,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'none',
    alignSelf: 'center',
    minHeight: 25,
    marginBottom: 65,
  },
  seeAllButtonText: {
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 10,
    letterSpacing: 0.45,
    fontSize: 11,
    color: color.white,
  },
  seeAllButtonVisible: {
    display: 'flex',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  sideSpacer: {
    flex: 1.5,
    display: 'none',
    alignSelf: 'baseline',
  },
  sideSpacerPC: {
    display: 'flex',
  },
})
