import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, StyleSheet, View } from 'react-native'

import logoImage from '~/assets/images/logo-white.png'
import ModalContainer from '~/components/common/atoms/ModalContainer'
import LoginColumn from '~/components/common/features/auth/LoginColumn'
import Button from '~/components/workarounds/Button'
import Link from '~/components/workarounds/Link'
import Text from '~/components/workarounds/Text'
import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { Fonts, FontSize } from '~/constants/common/font'
import useDevice from '~/hooks/commons/useDevice'
import { saveCurrentRouteAsyncStore } from '~/utils/navigation'

const OutsiderGuide: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const [isShowLogin, setIsShowLogin] = useState(false)

  useEffect(() => {
    return (): void => {
      setIsShowLogin(false)
    }
  }, [])

  const loginPress = (): void => {
    setIsShowLogin(true)
    saveCurrentRouteAsyncStore()
  }
  const signupPress = (): void => {
    saveCurrentRouteAsyncStore()
  }

  return (
    <>
      <View style={styles.container} testID="outsider-guide">
        <View style={!isPC ? styles.logoGroup : undefined}>
          <Image
            source={logoImage}
            style={isPC ? styles.logoStyle : styles.logoStyleMobile}
          />
          <Text style={styles.guideText}>{t('outsider.outsiderGuide')}</Text>
        </View>
        <View style={isPC ? styles.buttonGroup : styles.buttonGroupMobile}>
          <Button
            type="solid"
            testID="login-button"
            title={t('auth.login')}
            onPress={loginPress}
            containerStyle={StyleSheet.flatten([
              isPC ? styles.containerButton : styles.containerButtonMobile,
            ])}
            titleStyle={styles.buttonLogin}
          />
          <Link to="/signup" onPress={signupPress} style={styles.signUp}>
            <Text style={styles.signUpText}>{t('auth.signup')}</Text>
          </Link>
        </View>
      </View>
      {isShowLogin && (
        <ModalContainer
          visible={isShowLogin}
          onDismiss={(): void => setIsShowLogin(false)}
        >
          <View style={styles.loginContainer}>
            <LoginColumn />
          </View>
        </ModalContainer>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  buttonGroup: {
    marginLeft: 10,
  },
  buttonGroupMobile: {
    marginRight: 20,
  },
  buttonLogin: {
    fontSize: FontSize.REMARKABLE,
  },
  container: {
    alignItems: 'center',
    backgroundColor: color.mask,
    bottom: 0,
    flexDirection: 'row',
    height: 157,
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
  containerButton: {
    height: 45,
    width: 198,
  },
  containerButtonMobile: {
    height: 45,
    width: 170,
  },
  guideText: {
    color: color.white,
    fontSize: FontSize.REMARKABLE,
    paddingHorizontal: 20,
  },
  loginContainer: {
    backgroundColor: color.white,
    ...commonStyles.borderRadiusAllCorner,
    paddingBottom: 25,
    paddingHorizontal: 50,
    paddingTop: 50,
  },
  logoGroup: {
    flex: 1,
    flexDirection: 'column',
  },
  logoStyle: {
    height: 68,
    width: 233,
  },
  logoStyleMobile: {
    height: 24,
    width: 100,
    marginBottom: 15,
    marginLeft: 15,
  },
  signUp: {
    alignSelf: 'center',
    marginTop: 10,
  },
  signUpText: {
    color: color.white,
    fontFamily: Fonts.MediumFont,
    fontSize: FontSize.REMARKABLE,
  },
})

export default OutsiderGuide
