import React from 'react'
import { Image, StyleSheet, Text, View, ViewStyle } from 'react-native'

import color from '~/constants/common/color'

type Props = {
  icon: string
  iconSize?: 'small' | 'medium' | 'large'
  title: string
  description: string
}

const ProfileCard: React.FC<Props> = ({
  icon,
  iconSize = 'small',
  title,
  description,
}: Props) => {
  let iconStyle
  if (iconSize === 'small') {
    iconStyle = styles.smallIcon
  }
  if (iconSize === 'medium') {
    iconStyle = styles.mediumIcon
  }
  if (iconSize === 'large') {
    iconStyle = styles.largeIcon
  }

  return (
    <View style={styles.wrapper} testID="profile-card">
      <Image
        source={{ uri: icon }}
        style={iconStyle}
        testID="profile-icon-image"
      />
      <View style={styles.detail}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  description: {
    color: color.textGray,
    fontSize: 14,
    lineHeight: 20,
  } as ViewStyle,
  detail: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  largeIcon: {
    width: 66,
    height: 66,
  },
  mediumIcon: {
    width: 54,
    height: 54,
  },
  smallIcon: {
    width: 42,
    height: 42,
  },
  title: {
    color: color.black,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 20,
  } as ViewStyle,
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    gap: 16,
    height: 'max-content',
  } as ViewStyle,
})

export default ProfileCard
