import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  Image,
  StyleSheet,
  useWindowDimensions,
  TextStyle,
  ViewStyle,
} from 'react-native'

import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

const FONT_SIZES = {
  mobile: { title: 30, subtitle: 14 },
  tablet: { title: 30, subtitle: 14 },
  desktop: { title: 35, subtitle: 14 },
  pc: { title: 70, subtitle: 25 },
}

const IMAGE_SIZES = {
  mobile: { width: 123, height: 164 },
  tablet: { width: 200, height: 250 },
  desktop: { width: 349, height: 465 },
}

const PADDING_PC = {
  left: 285,
  right: 216,
}

interface Props {
  ministers: { name: string; image: number }[]
}

const IntroMinisters = ({ ministers }: Props) => {
  const { isPC, isTablet, isMobile } = useDevice()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const scaleFactor = width > 1920 ? 1 : Math.max(width / 1920, 0.5)

  const fontSizes =
    isTablet || isMobile ? FONT_SIZES.mobile : FONT_SIZES.desktop

  const imageSize = isMobile
    ? IMAGE_SIZES.mobile
    : isTablet
    ? IMAGE_SIZES.tablet
    : {
        width: IMAGE_SIZES.desktop.width * scaleFactor,
        height: IMAGE_SIZES.desktop.height * scaleFactor,
      }

  const pcTitleStyle = {
    fontSize: FONT_SIZES.pc.title * scaleFactor,
    lineHeight: 84 * scaleFactor,
    textAlign: 'left',
  }
  const pcSubtitleStyle = {
    fontSize: FONT_SIZES.pc.subtitle * scaleFactor,
    lineHeight: 36 * scaleFactor,
    textAlign: 'left',
  }
  const pcContentViewStyle = {
    paddingLeft: PADDING_PC.left * scaleFactor,
    paddingRight: PADDING_PC.right * scaleFactor,
    flexDirection: 'row',
  }

  const containerStyle = [baseStyles.container, isPC && pcStyles.container]

  const titleStyle = [
    baseStyles.title,
    { fontSize: fontSizes.title },
    isPC && pcTitleStyle,
    isTablet && tabletStyles.title,
    isMobile && mobileStyles.title,
  ] as TextStyle

  const subtitleStyle = [
    baseStyles.subtitle,
    { fontSize: fontSizes.subtitle },
    isPC && pcSubtitleStyle,
    isTablet && tabletStyles.subtitle,
    isMobile && mobileStyles.subtitle,
  ] as TextStyle

  const contentViewStyle = [
    baseStyles.contentView,
    isPC && pcContentViewStyle,
    isTablet && tabletStyles.contentView,
    isMobile && mobileStyles.contentView,
  ] as ViewStyle

  const ministersImagesStyle = [
    baseStyles.ministersImages,
    isPC && pcStyles.ministersImages,
    isTablet && tabletStyles.ministersImages,
    isMobile && mobileStyles.ministersImages,
  ]

  return (
    <View style={containerStyle} testID="intro-ministers-section">
      {!isPC && (
        <Text style={titleStyle}>
          {t('landing.oneTimeIntro.ministers.title')}
        </Text>
      )}
      <View style={contentViewStyle}>
        <View style={baseStyles.flex1}>
          {isPC && (
            <Text style={[titleStyle, baseStyles.mb50]}>
              {t('landing.oneTimeIntro.ministers.title')}
            </Text>
          )}
          <Text style={subtitleStyle}>
            {t('landing.oneTimeIntro.ministers.subtitle')}
          </Text>
        </View>
        <View style={ministersImagesStyle}>
          {ministers.map((minister, index) => (
            <Image
              key={index}
              source={minister.image}
              style={[baseStyles.ministerImage, imageSize]}
              resizeMode="cover"
              testID={`minister-image-${index}`}
            />
          ))}
        </View>
      </View>
    </View>
  )
}

const baseStyles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 20,
    maxWidth: 1920,
    paddingVertical: 35,
  },
  contentView: {
    flex: 1,
    gap: 70,
  },
  flex1: { flex: 1 },
  mb50: { marginBottom: 50 },
  ministerImage: {
    borderRadius: 8,
  },
  ministersImages: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 2,
  },
  subtitle: {
    color: color.outerSpace,
    marginBottom: 20,
    alignSelf: 'flex-start',
  },
  title: {
    fontWeight: 'bold',
    color: color.outerSpace,
    marginBottom: 10,
    alignSelf: 'flex-start',
  },
})

const pcStyles = StyleSheet.create({
  container: {
    paddingVertical: 222,
  },
  ministersImages: {
    gap: 46,
  } as ViewStyle,
})

const tabletStyles = StyleSheet.create({
  contentView: {
    gap: 40,
    flexDirection: 'column-reverse',
    maxWidth: '80%',
  } as ViewStyle,
  ministersImages: {
    gap: 30,
    marginBottom: 30,
  } as ViewStyle,
  subtitle: {
    textAlign: 'center',
    alignSelf: 'center',
    width: '80%',
  },
  title: {
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 70,
  },
})

const mobileStyles = StyleSheet.create({
  contentView: {
    gap: 40,
    flexDirection: 'column-reverse',
  } as ViewStyle,
  ministersImages: {
    gap: 20,
    marginBottom: 20,
  } as ViewStyle,
  subtitle: {
    textAlign: 'center',
    marginBottom: 60,
    width: '90%',
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
    alignSelf: 'center',
  },
})

export default IntroMinisters
