import React from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  useWindowDimensions,
  ViewStyle,
  TextStyle,
  ImageStyle,
} from 'react-native'
import { useSelector } from 'react-redux'

import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import { RootState } from '~/rootReducer'
import { navigate } from '~/utils/navigation'

const BASE_FONT_SIZES = {
  pc: { title: 88, subtitle: 23, buttonText: 23 },
  tablet: { title: 50, subtitle: 18, buttonText: 18 },
  mobile: { title: 26, subtitle: 12, buttonText: 12 },
}

const BASE_IMAGE_SIZE = {
  pc: { width: 1131, height: 947 },
  tablet: { width: 800, height: 670 },
  mobile: { width: '100%', height: 300 },
}

const BASE_LAYOUT = {
  pc: {
    padding: 20,
    paddingHorizontal: 40,
    paddingLeft: 126,
    buttonWidth: 192,
    gap: 20,
  },
  tablet: {
    padding: 15,
    paddingHorizontal: 20,
    paddingLeft: 0,
    buttonWitdh: undefined,
    gap: 20,
  },
  mobile: {
    padding: 10,
    paddingHorizontal: 10,
    paddingLeft: 0,
    buttonWitdh: undefined,
    gap: 10,
  },
}

interface Props {
  title: string
  subtitle: string
  buttonTitle?: string
}

const IntroHeader = ({ title, subtitle, buttonTitle }: Props) => {
  const { isPC, isTablet, isMobile } = useDevice()
  const { width } = useWindowDimensions()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const scaleFactor = width < 1920 ? Math.max(width / 1920, 0.5) : 1
  const isColumnLayout = !isPC

  const words = title.trim().split(' ')
  const lastTwoWords =
    words.length >= 2 ? words.slice(-2).join(' ') : words[0] || ''
  const firstPart = words.length >= 2 ? words.slice(0, -2).join(' ') : ''

  const fontSizes = isMobile
    ? BASE_FONT_SIZES.mobile
    : isTablet
    ? BASE_FONT_SIZES.tablet
    : {
        title: BASE_FONT_SIZES.pc.title * scaleFactor,
        subtitle: BASE_FONT_SIZES.pc.subtitle * scaleFactor,
        buttonText: BASE_FONT_SIZES.pc.buttonText * scaleFactor,
      }

  const imageSize = isMobile
    ? BASE_IMAGE_SIZE.mobile
    : isTablet
    ? BASE_IMAGE_SIZE.tablet
    : {
        width: BASE_IMAGE_SIZE.pc.width * scaleFactor,
        height: BASE_IMAGE_SIZE.pc.height * scaleFactor,
      }

  const layout = isMobile
    ? BASE_LAYOUT.mobile
    : isTablet
    ? BASE_LAYOUT.tablet
    : {
        padding: BASE_LAYOUT.pc.padding,
        paddingHorizontal: BASE_LAYOUT.pc.paddingHorizontal * scaleFactor,
        paddingLeft: BASE_LAYOUT.pc.paddingLeft * scaleFactor,
        buttonWidth: BASE_LAYOUT.pc.buttonWidth * scaleFactor,
        gap: BASE_LAYOUT.pc.gap,
      }

  const headerContainerStyle = {
    padding: layout.padding,
    flexDirection: isColumnLayout ? 'column' : 'row',
    backgroundColor: color.white,
    justifyContent: isColumnLayout ? 'center' : 'space-between',
    paddingHorizontal: layout.paddingHorizontal,
    paddingTop: isMobile ? 50 : isTablet ? 80 : undefined,
    height: isColumnLayout ? undefined : 1050 * scaleFactor,
    alignItems: 'center',
    position: 'relative',
  } as ViewStyle

  const titleSectionStyle = {
    flex: 1,
    gap: layout.gap,
    maxWidth: isPC ? '50%' : '80%',
    paddingLeft: layout.paddingLeft,
    alignItems: isColumnLayout ? 'center' : 'flex-start',
  } as TextStyle

  const titleStyle = {
    fontSize: fontSizes.title,
    fontWeight: 'bold',
    color: color.outerSpace,
    marginBottom: 10,
    textAlign: isColumnLayout ? 'center' : 'left',
  } as TextStyle

  const lastTitleStyle = {
    fontSize: fontSizes.title,
    fontWeight: 'bold',
    color: color.primary,
  } as TextStyle

  const subtitleStyle = {
    fontSize: fontSizes.subtitle,
    color: color.outerSpace,
    textAlign: isColumnLayout ? 'center' : 'left',
    marginBottom: 20,
    maxWidth: isPC ? '65%' : '100%',
    lineHeight: fontSizes.subtitle + 10,
  } as TextStyle

  const buttonStyle = {
    backgroundColor: color.primary,
    borderRadius: 3,
    alignItems: 'center',
    alignSelf: isColumnLayout ? 'center' : 'baseline',
    paddingHorizontal: isMobile ? 15 : 25,
    paddingVertical: isMobile ? 8 : 10,
    width: 192,
  } as ViewStyle

  const buttonTextStyle = {
    color: color.white,
    fontWeight: 'bold',
    fontSize: fontSizes.buttonText,
  } as TextStyle

  const imageBackgroundStyle = {
    width: imageSize.width,
    height: imageSize.height,
    position: isColumnLayout ? 'relative' : 'absolute',
    right: isColumnLayout ? undefined : 0,
    marginTop: isColumnLayout ? 30 : 0,
    resizeMode: 'cover',
  } as ImageStyle

  const redirectToRegister = () => {
    navigate(accessToken ? 'communityMain' : 'signup')
  }

  return (
    <View style={headerContainerStyle} testID="intro-header">
      <View style={titleSectionStyle}>
        <Text style={titleStyle}>
          {firstPart}
          <Text style={lastTitleStyle}>{lastTwoWords}</Text>
        </Text>
        <Text style={subtitleStyle}>{subtitle}</Text>
        {buttonTitle && (
          <TouchableOpacity style={buttonStyle} onPress={redirectToRegister}>
            <Text style={buttonTextStyle}>{buttonTitle}</Text>
          </TouchableOpacity>
        )}
      </View>
      <ImageBackground
        source={require('~/assets/images/landingPage/intro_people.png')}
        style={imageBackgroundStyle}
      />
    </View>
  )
}

export default IntroHeader
