import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
  TextStyle,
} from 'react-native'

import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

interface Feature {
  title: string
  description: string
  backgroundColor: string
  onPress: () => void
}

interface Props {
  features: Feature[]
}

const FeatureBox = ({
  feature,
  boxStyle,
}: {
  feature: Feature
  boxStyle: ViewStyle
}) => (
  <TouchableOpacity style={boxStyle} onPress={feature.onPress}>
    <Text style={styles.featureTitle} testID="feature-box-title">
      {feature.title}
    </Text>
    <Text style={styles.featureDescription} testID="feature-box-description">
      {feature.description}
    </Text>
  </TouchableOpacity>
)

const GetInvolvedSection = ({ features }: Props) => {
  const { isPC, isMobile } = useDevice()
  const { t } = useTranslation()

  const subtitleKey = isMobile ? 'subtitleMobile' : 'subtitle'

  const getFeatureBoxStyle = (feature: Feature) =>
    [
      styles.featureBox,
      !isPC && styles.featureBoxMobile,
      { backgroundColor: feature.backgroundColor },
    ] as ViewStyle

  const getDescriptionStyle = () =>
    [
      styles.description,
      !isPC && styles.descriptionMobile,
      isMobile && styles.descriptionAdjust,
    ] as TextStyle

  const getHeaderTitleStyle = () => [
    styles.header,
    isMobile && styles.headerMobile,
  ]

  const getContainerStyle = () => [
    styles.container,
    isMobile && {
      paddingTop: 54,
      paddingBottom: 82,
    },
  ]

  return (
    <View style={getContainerStyle()}>
      <TitleText
        style={getHeaderTitleStyle()}
        testID="get-involved-section-title"
      >
        {t('landing.involved.title')}
      </TitleText>
      <Text
        style={getDescriptionStyle()}
        testID="get-involved-section-description"
      >
        {t(`landing.involved.${subtitleKey}`)}
      </Text>

      <View
        style={[
          styles.featuresContainer,
          !isPC && styles.featuresContainerMobile,
        ]}
      >
        {features.map((feature, index) => (
          <FeatureBox
            feature={feature}
            boxStyle={getFeatureBoxStyle(feature)}
            key={index}
          />
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    backgroundColor: color.white,
    paddingVertical: 150,
  },
  description: {
    fontSize: 16,
    textAlign: 'center',
    color: color.steelGray,
    lineHeight: 22,
    marginBottom: 103,
    maxWidth: '45%',
    alignSelf: 'center',
  },
  descriptionAdjust: {
    fontSize: 13,
    lineHeight: 16,
    marginBottom: 32.31,
    marginTop: 17.69,
    marginHorizontal: 48.93,
  },
  descriptionMobile: {
    maxWidth: '95%',
  },
  featureBox: {
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    flex: 1,
    paddingHorizontal: 20,
  },
  featureBoxMobile: {
    height: 140,
    marginBottom: 0,
    paddingVertical: 30,
  },
  featureDescription: {
    fontSize: 17,
    lineHeight: 15,
    color: color.white,
    textAlign: 'center',
    marginTop: 10,
  },
  featureTitle: {
    fontSize: 21,
    fontWeight: 'bold',
    color: color.white,
    textAlign: 'center',
    marginBottom: 5,
    lineHeight: 19.2,
    letterSpacing: 0.84,
  },
  featuresContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 20,
    paddingHorizontal: 95,
  },
  featuresContainerMobile: {
    flexDirection: 'column',
    gap: 22,
    paddingHorizontal: undefined,
  },
  header: {
    fontSize: 34,
    fontWeight: 'bold',
    textAlign: 'center',
    color: color.black,
    marginBottom: 10,
  },
  headerMobile: {
    fontSize: 26,
    lineHeight: 28.2,
    letterSpacing: 0.75,
    fontWeight: 'bold',
  },
})

export default GetInvolvedSection
