import { TFunction } from 'i18next'

type Region = {
  name: string
  image: number
}

export const getRegions = (
  t: TFunction,
  isWorldwideSelected: boolean
): Region[] => {
  switch (isWorldwideSelected) {
    case false:
      return [
        {
          name: t('landing.find.regions.hokkaido'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Hokkaido.png'),
        },
        {
          name: t('landing.find.regions.tohoku'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Tohoku.png'),
        },
        {
          name: t('landing.find.regions.kanto'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Kanto.png'),
        },
        {
          name: t('landing.find.regions.chubu'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Chubu.png'),
        },
        {
          name: t('landing.find.regions.kinki'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Kansai.png'),
        },
        {
          name: t('landing.find.regions.chugoku'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Chugoku.png'),
        },
        {
          name: t('landing.find.regions.shikoku'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Shikoku.png'),
        },
        {
          name: t('landing.find.regions.kyushuOkinawa'),
          image: require('~/assets/images/landingPage/highlightedJapanMap/Kyushu.png'),
        },
      ]

    default:
      return [
        {
          name: t('Europe'),
          image: require('~/assets/images/landingPage/highlightedWorldwideMap/Europe.png'),
        },
        {
          name: t('South America'),
          image: require('~/assets/images/landingPage/highlightedWorldwideMap/SouthAmerica.png'),
        },
        {
          name: t('Africa'),
          image: require('~/assets/images/landingPage/highlightedWorldwideMap/Africa.png'),
        },
        {
          name: t('North America'),
          image: require('~/assets/images/landingPage/highlightedWorldwideMap/NorthAmerica.png'),
        },
        {
          name: t('Asia'),
          image: require('~/assets/images/landingPage/highlightedWorldwideMap/Asia.png'),
        },
        {
          name: t('Australia'),
          image: require('~/assets/images/landingPage/highlightedWorldwideMap/Australia.png'),
        },
      ]
  }
}

export const getCategories = (t: TFunction) => [
  t('landing.find.categories.WorkplaceAndOccupations'),
  t(
    'landing.find.categories.schoolsGraduateSchoolsAndIntegratedEducationSchool'
  ),
  t('landing.find.categories.sportsAssociation'),
  t('landing.find.categories.sportsRelated'),
  t('landing.find.categories.neighborhoodCouncil'),
  t('landing.find.categories.federationOfCulturalOrganizations'),
  t('landing.find.categories.seminar'),
]
