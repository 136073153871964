export enum Fonts {
  NormalFont = 'Roboto',
  MediumFont = 'RobotoMedium',
  JapaneseFont = 'NotoSansJPRegular',
}

export enum FontSize {
  IMPORTANT = 26,
  REMARKABLE = 16,
  GENERAL = 15,
  SUB = 13,
}

export type FontSizeKey = 'small' | 'medium' | 'large' | 'xLarge'

export const FONT_SIZE_MAP: Record<FontSizeKey, number> = {
  small: 12,
  medium: 16,
  large: 20,
  xLarge: 80,
}
