import { useRoute } from '@react-navigation/native'
import { TFunction } from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import Logo from '~/components/common/molecules/Logo'
import TopTabMenuItem from '~/components/common/molecules/TopTabMenuItem'
import color from '~/constants/common/color'
import {
  getNavigationMenu,
  MenuItemType,
  NavigationTab,
} from '~/constants/landingPage/Navigation'
import { RootState } from '~/rootReducer'

interface Props {
  redirectToLoginOrRegister: (screen: 'login' | 'signup') => void
  onPressItem: (tabName: NavigationTab, selectedSubmenuIndex?: number) => void
  handleLogout: () => void
  isMenuOpen: boolean
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const LandingPageMobileMenu = ({
  redirectToLoginOrRegister,
  onPressItem,
  handleLogout,
  isMenuOpen,
  setIsMenuOpen,
}: Props) => {
  const { t } = useTranslation()
  const navigationMenu = getNavigationMenu(t)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null)

  const route = useRoute<{
    key: string
    name: string
    params: { tab?: string; selectedSubmenuIndex?: number }
  }>()
  const activeTab = route.params?.tab
  const selectedSubmenuIndex = route.params?.selectedSubmenuIndex

  const toggleSubmenu = (menuItem: MenuItemType) => {
    setOpenSubmenu((prev) => (prev === menuItem.name ? null : menuItem.name))
    if (!menuItem.submenu?.length) onPressItem(menuItem.link)
  }

  return (
    <Modal
      transparent
      visible={isMenuOpen}
      animationType="fade"
      testID="landing-page-mobile-menu"
    >
      <View style={styles.backdrop} testID="mobile-menu">
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Logo isHideText />
          </View>
          <TouchableOpacity
            onPress={() => setIsMenuOpen(false)}
            style={styles.menuButton}
            testID="close-menu-button"
          >
            <Text style={styles.menuIcon}>☰</Text>
          </TouchableOpacity>
        </View>

        <View style={styles.mobileMenu}>
          {navigationMenu.map((item) => (
            <TopTabMenuItem
              key={item.name}
              item={item}
              selectedTab={activeTab}
              expandedSubmenu={openSubmenu}
              selectedSubmenuIndex={selectedSubmenuIndex}
              onPressItem={onPressItem}
              onPress={() => toggleSubmenu(item)}
            />
          ))}
          <AuthButtons
            accessToken={accessToken}
            redirectToLoginOrRegister={redirectToLoginOrRegister}
            handleLogout={handleLogout}
            t={t}
          />
        </View>
      </View>
    </Modal>
  )
}

export default LandingPageMobileMenu

const AuthButtons = ({
  accessToken,
  redirectToLoginOrRegister,
  handleLogout,
  t,
}: {
  accessToken?: string
  redirectToLoginOrRegister: (screen: 'login' | 'signup') => void
  handleLogout: () => void
  t: TFunction
}) => (
  <View style={styles.authContainer}>
    {!accessToken ? (
      <>
        <TouchableOpacity onPress={() => redirectToLoginOrRegister('login')}>
          <Text style={styles.authButton}>{t('auth.login')}/</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => redirectToLoginOrRegister('signup')}>
          <Text style={styles.authButton}>{t('auth.register')}</Text>
        </TouchableOpacity>
      </>
    ) : (
      <TouchableOpacity onPress={handleLogout}>
        <Text style={styles.mobileLogoutText} testID="logout-button">
          {t('auth.logout')}
        </Text>
      </TouchableOpacity>
    )}
  </View>
)

const styles = StyleSheet.create({
  authButton: { color: color.outerSpace, fontSize: 14, letterSpacing: 0.41 },
  authContainer: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: color.gray,
    paddingVertical: 20,
    paddingLeft: 20,
  },
  backdrop: {
    flex: 1,
    backgroundColor: color.mask,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoContainer: {
    backgroundColor: color.white,
    paddingHorizontal: 20,
    paddingVertical: 14,
    width: '70%',
  },
  menuButton: { paddingVertical: 14, paddingRight: 30 },
  menuIcon: { fontSize: 30, color: color.white },
  mobileLogoutText: { fontSize: 18, fontWeight: 'bold', color: color.danger },
  mobileMenu: {
    backgroundColor: color.white,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: color.chineseSilver,
    paddingHorizontal: 10,
    gap: 15,
    paddingTop: 15,
    width: '70%',
  },
})
