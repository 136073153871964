import { NavigationContainer, DefaultTheme } from '@react-navigation/native'
import { first } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { Dimensions } from 'react-native'
import type { ScaledSize } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import RootStack, { appScreenOptions } from '~/components/app/RootStack'
import CareerMain from '~/components/career/pages/RootPage/CareerMain'
import ResumeShow from '~/components/career/pages/resume/ResumeShow'
import CompanyLanding from '~/components/common/pages/CompanyLanding'
import Privacy from '~/components/common/pages/Privacy'
import Registration from '~/components/common/pages/Registration'
import Terms from '~/components/common/pages/Terms'
import TopPage from '~/components/common/pages/TopPage'
import CompanySignup from '~/components/common/pages/auth/CompanySignup'
import Login from '~/components/common/pages/auth/Login'
import RequestPasswordReset from '~/components/common/pages/auth/RequestPasswordReset'
import ResetPassword from '~/components/common/pages/auth/ResetPassword'
import Signup from '~/components/common/pages/auth/Signup'
import Plan from '~/components/common/pages/subscription/SubscriptionPlan'
import CommunityMain from '~/components/community/pages/RootPage/CommunityMain'
import ForumMain from '~/components/forum/pages/RootPage/ForumMain'
import color from '~/constants/common/color'
import { RootContextProvider } from '~/contexts/RootContext'
import useDevice from '~/hooks/commons/useDevice'
import useCustomToast from '~/hooks/useCustomToast'
import { RootState } from '~/rootReducer'
import { fetchCompany } from '~/slices/career/company'
import { setCurrentScreen } from '~/utils/common/firebase'
import {
  navigationRef,
  getLinking,
  isReadyNavigationRef,
  screenRef,
} from '~/utils/navigation'

const RootPage: React.FC = () => {
  const dispatch = useDispatch()
  const toast = useCustomToast()
  const routeNameRef = useRef<string>()
  const isPC = useSelector((state: RootState) => state.device.isPC)
  const user = useSelector((state: RootState) => state.users.current)
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)
  const { setDeviceType } = useDevice()

  const companyId = first(user?.profile?.companies)?.id
  useEffect(() => {
    if (companyId == null) {
      return
    }
    try {
      dispatch(fetchCompany(companyId))
    } catch (error) {
      toast.showError(error)
    }
  }, [companyId])

  const shouldRegisterProfile =
    (user != null && user.profile == null) || (accessToken && !user?.profile)

  useEffect(() => {
    const handler = (e: { window: ScaledSize }): void => {
      setDeviceType(e.window.width)
    }
    const event = Dimensions.addEventListener('change', handler)
    return (): void => {
      event.remove()
      isReadyNavigationRef.current = false
    }
  }, [dispatch])

  const logScreen = (currentRouteName: string): void => {
    //NOTE No log with staging. Can't check staging with mobile.
    if (window.location?.hostname !== 'staging.enjin.world') {
      setCurrentScreen(currentRouteName)
    }
  }

  return (
    <RootContextProvider>
      <NavigationContainer
        ref={navigationRef}
        theme={EnjinTheme}
        onReady={(): void => {
          isReadyNavigationRef.current = true
          routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name
        }}
        linking={getLinking(isPC)}
        onStateChange={(): void => {
          const previousRouteName = routeNameRef.current
          const currentRouteName = navigationRef.current?.getCurrentRoute()
            ?.name
          if (previousRouteName !== currentRouteName) {
            currentRouteName && logScreen(currentRouteName)
          }
          routeNameRef.current = currentRouteName
        }}
        documentTitle={{
          formatter: (options, route): string => {
            return screenRef.title ?? options?.title ?? route?.name
          },
        }}
      >
        {shouldRegisterProfile ? (
          // Registration
          <RootStack.Navigator initialRouteName="registration">
            <RootStack.Screen
              name="registration"
              component={Registration}
              options={{ headerShown: false }}
            />
            {/* To be able to navigate to subscriptionPlan */}
            <RootStack.Group screenOptions={appScreenOptions.topPage}>
              <RootStack.Screen name="subscriptionPlan" component={Plan} />
            </RootStack.Group>
          </RootStack.Navigator>
        ) : (
          <RootStack.Navigator initialRouteName="topPage">
            <RootStack.Group screenOptions={appScreenOptions.landingPage}>
              <RootStack.Screen name="topPage" component={TopPage} />
            </RootStack.Group>
            <RootStack.Group screenOptions={appScreenOptions.topPage}>
              <RootStack.Screen name="subscriptionPlan" component={Plan} />
            </RootStack.Group>
            <RootStack.Group screenOptions={appScreenOptions.career}>
              <RootStack.Screen name="careerMain" component={CareerMain} />
            </RootStack.Group>
            <RootStack.Group screenOptions={appScreenOptions.forum}>
              <RootStack.Screen name="forumMain" component={ForumMain} />
            </RootStack.Group>
            <RootStack.Group screenOptions={appScreenOptions.community}>
              <RootStack.Screen
                name="communityMain"
                component={CommunityMain}
              />
            </RootStack.Group>
            {/* Authentication */}
            <RootStack.Group screenOptions={appScreenOptions.authentication}>
              {!accessToken && (
                <RootStack.Group>
                  <RootStack.Screen name="login" component={Login} />
                  <RootStack.Screen name="signup" component={Signup} />
                  <RootStack.Screen
                    name="companySignUp"
                    component={CompanySignup}
                  />
                  <RootStack.Screen
                    name="landingCompany"
                    component={CompanyLanding}
                  />
                  <RootStack.Screen
                    name="requestPasswordReset"
                    component={RequestPasswordReset}
                  />
                  <RootStack.Screen
                    name="resetPassword"
                    component={ResetPassword}
                  />
                </RootStack.Group>
              )}
              <RootStack.Screen name="privacy" component={Privacy} />
              <RootStack.Screen name="terms" component={Terms} />
            </RootStack.Group>
            {/* No header screen */}
            <RootStack.Group screenOptions={{ headerShown: false }}>
              <RootStack.Screen name="resumeShow" component={ResumeShow} />
            </RootStack.Group>
          </RootStack.Navigator>
        )}
      </NavigationContainer>
    </RootContextProvider>
  )
}

const EnjinTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    error: color.badgeColor,
    primary: color.primary,
  },
}

export default RootPage
