import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  TouchableOpacity,
  StyleSheet,
  ViewStyle,
  useWindowDimensions,
  Text,
  TextStyle,
} from 'react-native'
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome'

import Footer from '~/components/common/organisms/Footer'
import IntroCommunitiesSection from '~/components/common/organisms/IntroCommunitiesSection'
import IntroHeader from '~/components/common/organisms/IntroHeader'
import IntroJoinSection from '~/components/common/organisms/IntroJoinSection'
import IntroMinisters from '~/components/common/organisms/IntroMinisters'
import LandingPageBanner from '~/components/common/organisms/LandingPageBanner'
import WhatWeDo from '~/components/common/organisms/WhatWeDo'
import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import { navigate } from '~/utils/navigation'

const OneTimeIntro = () => {
  const { t } = useTranslation()
  const { isPC, isTablet, isMobile } = useDevice()
  const { width } = useWindowDimensions()

  const introActionButtons = [
    {
      title: t('landing.oneTimeIntro.globalConnections'),
      iconName: 'globe',
      backgroundColor: '#8CBFB8',
      onPress: () => navigate('communityMain'),
    },
    {
      title: t('landing.find.worldwide'),
      iconName: 'globe',
      backgroundColor: '#53AC9F',
      onPress: () => navigate('communityMain'),
    },
    {
      title: t('landing.oneTimeIntro.communityHub'),
      iconName: 'globe',
      backgroundColor: '#249383',
      onPress: () => navigate('communityMain'),
    },
  ]

  const whatWeDoList = [
    {
      title: t('landing.oneTimeIntro.career'),
      items: [
        t('landing.oneTimeIntro.careerItem1'),
        t('landing.oneTimeIntro.careerItem2'),
        t('landing.oneTimeIntro.careerItem3'),
      ],
    },
    {
      title: t('landing.oneTimeIntro.forum'),
      items: [
        t('landing.oneTimeIntro.forumItem1'),
        t('landing.oneTimeIntro.forumItem2'),
        t('landing.oneTimeIntro.forumItem3'),
      ],
    },
    {
      title: t('landing.oneTimeIntro.community'),
      items: [
        t('landing.oneTimeIntro.communityItem1'),
        t('landing.oneTimeIntro.communityItem2'),
      ],
    },
  ]

  const ministers = [
    {
      name: 'Minister 1',
      image: require('~/assets/images/landingPage/minister1.png'),
    },
    {
      name: 'Minister 2',
      image: require('~/assets/images/landingPage/minister2.png'),
    },
  ]

  const communityImages = [
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community1.png'),
    },
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community2.png'),
    },
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community3.png'),
    },
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community4.png'),
    },
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community3.png'),
    },
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community4.png'),
    },
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community2.png'),
    },
    {
      title: t('landing.oneTimeIntro.communities.loremIpsum'),
      image: require('~/assets/images/landingPage/community1.png'),
    },
  ]

  const IntroActionButtons = ({
    buttons,
  }: {
    buttons: {
      title: string
      iconName: string
      backgroundColor: string
      onPress?: () => void
    }[]
  }) => {
    const buttonContainerStyles = [
      styles.actionButtonsContainer,
      isMobile && styles.actionButtonsContainerMobile,
      isTablet && styles.actionButtonsContainerTablet,
      isPC && styles.actionButtonsContainerPC,
    ]

    const buttonStyle = [
      styles.actionButton,
      isPC && styles.actionButtonPC,
    ] as ViewStyle

    const buttonTitleStyles = [
      { color: color.white, fontWeight: 'bold' },
      isPC && { fontSize: 38 },
    ] as TextStyle

    return (
      <View style={buttonContainerStyles} testID="intro-action-buttons">
        {buttons.map((button, index) => (
          <TouchableOpacity
            key={index}
            style={[buttonStyle, { backgroundColor: button.backgroundColor }]}
            onPress={button.onPress}
            testID={`action-button-${index}`}
          >
            <FontAwesomeIcon
              name={button.iconName}
              size={isMobile ? 40 : 90}
              color={color.white}
            />
            <Text style={buttonTitleStyles}>{button.title}</Text>
          </TouchableOpacity>
        ))}
      </View>
    )
  }

  return (
    <View style={styles.container} testID="one-time-intro">
      <IntroHeader
        title={t('landing.oneTimeIntro.introHeaderTitle')}
        subtitle={t('landing.oneTimeIntro.introHeaderSubtitle')}
        buttonTitle={t('landing.oneTimeIntro.joinUsNow')}
      />

      <View style={[styles.sectionsWrapper, { width }]}>
        <LandingPageBanner
          title={t('landing.oneTimeIntro.bannerTitle')}
          subTitle={t('landing.oneTimeIntro.bannerSubtitle')}
          inverted
          actionButtons={<IntroActionButtons buttons={introActionButtons} />}
        />

        <WhatWeDo sections={whatWeDoList} />

        <IntroMinisters ministers={ministers} />

        <LandingPageBanner
          title={t('landing.oneTimeIntro.communities.title')}
          subTitle={t('landing.oneTimeIntro.communities.subtitle')}
          inverted
          backgroundColor={color.white}
          actionButtons={
            <IntroCommunitiesSection communityImages={communityImages} />
          }
        />
        <IntroJoinSection />
      </View>
      <Footer />
    </View>
  )
}

const styles = StyleSheet.create({
  actionButton: {
    paddingVertical: 20,
    paddingHorizontal: 40,
    borderRadius: 8,
    alignItems: 'center',
    gap: 10,
    width: 250,
    justifyContent: 'center',
  },
  actionButtonPC: {
    width: 456,
    height: 250,
    fontSize: 38,
  },
  actionButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 20,
    paddingHorizontal: 10,
    gap: 50,
    flexWrap: 'wrap',
  },
  actionButtonsContainerMobile: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    fontSize: 16,
    gap: 10,
    flexDirection: 'column',
  },
  actionButtonsContainerPC: {
    paddingVertical: 20,
    paddingHorizontal: 40,
    fontSize: 20,
    gap: 60,
    justifyContent: 'center',
  },
  actionButtonsContainerTablet: {
    paddingVertical: 15,
    paddingHorizontal: 30,
    fontSize: 18,
    gap: 30,
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    maxWidth: 1920,
    maxHeight: 6612,
    alignSelf: 'center',
  },
  sectionsWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
})

export default OneTimeIntro
