import React, { useState } from 'react'
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'

import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'

interface Props {
  items: string[]
  onPress?: (index: number) => void
}

const PairButtons: React.FC<Props> = ({ items, onPress }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const { isMobile } = useDevice()

  const getButtonStyle = (index: number) => ({
    container: [
      styles.button,
      activeIndex === index ? styles.activeButton : styles.inactiveButton,
      isMobile && {
        minWidth: undefined,
        paddingHorizontal: 20,
        paddingVertical: 15,
      },
    ],
    text: [
      styles.buttonText,
      activeIndex === index ? styles.activeText : styles.inactiveText,
      isMobile && {
        fontSize: 11,
        lineHeight: 10,
        letterSpacing: 0.45,
      },
    ],
  })

  const buttonContainerStyle = [
    styles.buttonContainer,
    isMobile && {
      flexDirection: 'column',
      gap: 13.69,
    },
  ] as ViewStyle

  return (
    <View style={buttonContainerStyle} testID="pair-buttons">
      {items.map((title, index) => {
        const { container, text } = getButtonStyle(index)
        const onPressItem = () => {
          setActiveIndex(index)

          if (onPress !== undefined) {
            onPress(index)
          }
        }

        return (
          <TouchableOpacity
            key={index}
            style={container}
            onPress={onPressItem}
            testID={`button-${index}`}
          >
            <Text style={text} testID={`button-title-${index}`}>
              {title}
            </Text>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

export default PairButtons

const styles = StyleSheet.create({
  activeButton: {
    backgroundColor: color.white,
    borderColor: color.transparent,
    shadowColor: color.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  activeText: {
    color: color.steelGray,
  },
  button: {
    paddingVertical: 10,
    borderRadius: 5,
    paddingHorizontal: 20,
    minWidth: 200,
    borderWidth: 3,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 10,
    gap: 20,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  inactiveButton: {
    backgroundColor: color.primary,
    borderColor: color.white,
  },
  inactiveText: {
    color: color.white,
  },
})
