import React from 'react'
import { Text, View, StyleSheet } from 'react-native'

import color from '~/constants/common/color'
import commonStyles from '~/constants/common/commonStyles'
import { DeviceType } from '~/features/commons/deviceSlice'

type InfoCardType = {
  date: Date
  title: string
  description: string
}

const InfoCard: React.FC<{
  event: InfoCardType
  index: number
  deviceType: DeviceType
}> = ({ event, index, deviceType }) => {
  const isMobile = deviceType === 'mobile'

  return (
    <View
      style={[styles.eventItemCard, isMobile && styles.eventItemCardMobile]}
      testID={'info-card'}
    >
      <View
        style={[
          styles.eventDateContainer,
          isMobile && styles.eventDateContainerMobile,
        ]}
        testID={`info-date-${index}`}
      >
        <Text
          style={[
            styles.eventMonthText,
            isMobile && styles.eventMonthTextMobile,
          ]}
          testID={`info-month-${index}`}
        >
          {event.date.toDateString().split(' ')[0]}
        </Text>
        <Text
          style={[styles.eventDayText, isMobile && styles.eventDayTextMobile]}
          testID={`info-day-${index}`}
        >
          {event.date.toDateString().split(' ')[2]}
        </Text>
      </View>
      <View
        style={[styles.eventContent, isMobile && styles.eventContentMobile]}
        testID={`info-content-${index}`}
      >
        <Text
          style={[styles.eventHeading, isMobile && styles.eventHeadingMobile]}
          testID={`info-title-${index}`}
        >
          {event.title}
        </Text>
        <Text
          style={[styles.eventSummary, isMobile && styles.eventSummaryMobile]}
          testID={`info-summary-${index}`}
        >
          {event.description}
        </Text>
      </View>
    </View>
  )
}

export default InfoCard

const styles = StyleSheet.create({
  eventContent: { flex: 1, paddingTop: 10 },
  eventContentMobile: { paddingTop: 0 },
  eventDateContainer: {
    alignItems: 'center',
    marginRight: 15,
    borderLeftWidth: 5,
    borderLeftColor: color.outerSpace,
    paddingLeft: 10,
    alignSelf: 'baseline',
  },
  eventDateContainerMobile: { borderLeftWidth: 3 },
  eventDayText: { fontSize: 40, fontWeight: 'bold', color: color.outerSpace },
  eventDayTextMobile: { fontSize: 21, lineHeight: 19.2, letterSpacing: 0.88 },
  eventHeading: {
    fontSize: 40,
    fontWeight: 'bold',
    color: color.outerSpace,
    paddingRight: 50,
  },
  eventHeadingMobile: { fontSize: 25 },
  eventItemCard: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingRight: 30,
    borderRadius: 10,
    marginBottom: 30,
  },
  eventItemCardMobile: {
    backgroundColor: color.white,
    paddingVertical: 23,
    paddingHorizontal: 30,
    ...commonStyles.shadow,
  },
  eventMonthText: { color: color.outerSpace, fontSize: 20 },
  eventMonthTextMobile: { fontSize: 10, lineHeight: 19.2, letterSpacing: 2 },
  eventSummary: { fontSize: 14, color: color.outerSpace, marginTop: 20 },
  eventSummaryMobile: { fontSize: 8, lineHeight: 11, marginTop: 8 },
})
