import { isNil } from 'lodash'
import React, { useCallback, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import type { ViewStyle } from 'react-native'

import RemoveButton from '~/components/common/atoms/RemoveButton'
import Retry from '~/components/common/atoms/Retry'
import type { RetryTarget } from '~/components/common/atoms/Retry'
import EmptyMedia from '~/components/common/atoms/media/EmptyMedia'
import MediaMask from '~/components/common/atoms/media/MediaMask'
import MediaThumbnail from '~/components/common/atoms/media/MediaThumbnail'
import Media from '~/interfaces/Media'
import { isVideo } from '~/interfaces/Video'

const styles = StyleSheet.create({
  individualItem: {
    flex: 1,
    height: '100%',
  },
  mainItem: {
    flex: 2,
  },
  removeIcon: {
    position: 'absolute',
    right: 24,
    top: 24,
  },
  subItem: {
    flex: 1,
  },
  touchable: {
    flex: 1,
  },
})

const ITEM_STYLES = {
  single: styles.individualItem,
  twin: styles.individualItem,
  main: styles.mainItem,
  topRight: styles.subItem,
  remaining: styles.subItem,
} as const

export type ItemMode = keyof typeof ITEM_STYLES

type Props = {
  media: Media
  mode: ItemMode
  maskText?: string
  removeIconSize?: number
  retry: boolean
  onPress?: () => void
  onRemove?: () => void
}

type ModeConfig = {
  small: boolean
  minHeight: number
}

const MODE_CONFIG: { [mode in ItemMode]: ModeConfig } = {
  single: { small: false, minHeight: 200 },
  twin: { small: false, minHeight: 150 },
  main: { small: false, minHeight: 100 },
  topRight: { small: true, minHeight: 100 },
  remaining: { small: true, minHeight: 100 },
}

const useLoaded = (): [boolean, () => void] => {
  const [loaded, setLoaded] = useState(false)
  const handleLoad = useCallback(() => {
    setLoaded(true)
  }, [setLoaded])
  return [loaded, handleLoad]
}

const useThumbnail = (
  uri: string,
  video: boolean,
  small: boolean,
  ignoreCache: boolean,
  onLoad: () => void
): RetryTarget =>
  useCallback(
    (retried: number, retry: () => void) => (
      <MediaThumbnail
        key={`thumbnail-${retried}`}
        video={video}
        small={small}
        uri={uri}
        ignoreCache={ignoreCache}
        onLoad={onLoad}
        onError={retry}
      />
    ),
    [uri, video, small, ignoreCache, onLoad]
  )

const MediaView: React.FC<Props> = ({
  media,
  mode,
  maskText,
  removeIconSize,
  retry,
  onPress,
  onRemove,
}: Props): React.ReactElement => {
  const [loaded, handleLoad] = useLoaded()
  const modeConfig = MODE_CONFIG[mode]
  const isPresigned = isNil(media?.urls?.original) && !isNil(media.uri)

  const thumbnail = useThumbnail(
    isVideo(media) ? media?.url : media?.urls?.original ?? media.uri,
    isVideo(media),
    modeConfig.small,
    isPresigned ? false : retry,
    handleLoad
  )

  const removeIcon =
    removeIconSize == null ? null : (
      <RemoveButton
        size={removeIconSize}
        style={StyleSheet.flatten([styles.removeIcon, { top: 2, right: 2 }])}
        onPress={onRemove}
      />
    )

  let content = (
    <>
      {loaded ? null : <EmptyMedia />}
      <Retry maxWait={60000} factor={1.5} retries={retry ? 10 : 0}>
        {thumbnail}
      </Retry>
      {removeIcon}
    </>
  )
  if (maskText != null) {
    content = <MediaMask text={maskText}>{content}</MediaMask>
  }

  if (onPress != null) {
    content = (
      <TouchableOpacity
        style={styles.touchable}
        testID="media-preview-item-touchable"
        activeOpacity={0.8}
        onPress={onPress}
      >
        {content}
      </TouchableOpacity>
    )
  }

  const containerStyles: ViewStyle[] = [ITEM_STYLES[mode]]
  if (!loaded) {
    containerStyles.push({ minHeight: modeConfig.minHeight })
  }

  return (
    <View
      testID="media-view-container"
      style={StyleSheet.flatten(containerStyles)}
    >
      {content}
    </View>
  )
}

export default MediaView
