import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
  ViewStyle,
  TextStyle,
  ImageStyle,
} from 'react-native'
import { useSelector } from 'react-redux'

import color from '~/constants/common/color'
import useDevice from '~/hooks/commons/useDevice'
import { RootState } from '~/rootReducer'
import { navigate } from '~/utils/navigation'

const FONT_SIZES = {
  mobile: { mainTitle: 40, subTitle: 18, description: 14, button: 16 },
  tablet: { mainTitle: 60, subTitle: 24, description: 16, button: 18 },
  desktop: { mainTitle: 100, subTitle: 40, description: 25, button: 21 },
  pcLarge: { mainTitle: 190, subTitle: 78, description: 25, button: 24 },
}

const IMAGE_SIZES = {
  mobile: { width: '100%', height: 200 },
  tablet: { width: '80%', height: 200 },
  smallPC: { width: 700, height: 360 },
  desktop: { width: 858.41, height: 442.04 },
}

const GAPS = {
  mobile: 20,
  tablet: 30,
  desktop: 50,
}

const IntroJoinSection = () => {
  const { isPC, isTablet, isMobile } = useDevice()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const accessToken = useSelector((state: RootState) => state.auth.accessToken)

  const isSmallPC = isPC && width < 1800
  const scaleFactor = isSmallPC ? width / 1920 : 1
  const gap = isMobile ? GAPS.mobile : isTablet ? GAPS.tablet : GAPS.desktop
  const flexDirection = isMobile ? 'column' : 'row'

  const fontSizes = isMobile
    ? FONT_SIZES.mobile
    : isTablet
    ? FONT_SIZES.tablet
    : isSmallPC
    ? {
        mainTitle: FONT_SIZES.desktop.mainTitle * scaleFactor,
        subTitle: FONT_SIZES.desktop.subTitle * scaleFactor,
        description: FONT_SIZES.desktop.description * scaleFactor,
        button: FONT_SIZES.desktop.button * scaleFactor,
      }
    : FONT_SIZES.desktop

  const imageSize = isMobile
    ? IMAGE_SIZES.mobile
    : isTablet
    ? IMAGE_SIZES.tablet
    : isSmallPC
    ? {
        width: IMAGE_SIZES.smallPC.width * scaleFactor,
        height: IMAGE_SIZES.smallPC.height * scaleFactor,
      }
    : IMAGE_SIZES.desktop

  const pcTitleStyle = {
    fontSize: FONT_SIZES.pcLarge.subTitle * scaleFactor,
    lineHeight: 71.2 * scaleFactor,
    textAlign: 'right',
  }
  const pcMainTitleStyle = {
    fontSize: FONT_SIZES.pcLarge.mainTitle * scaleFactor,
    lineHeight: 71.2 * scaleFactor,
  }
  const pcDescriptionStyle = {
    fontSize: FONT_SIZES.pcLarge.description * scaleFactor,
    lineHeight: 34 * scaleFactor,
    textAlign: 'left',
    maxWidth: '50%',
  }
  const pcButtonStyle = {
    fontSize: FONT_SIZES.pcLarge.button * scaleFactor,
    lineHeight: 19.2 * scaleFactor,
  }

  const containerStyle = [styles.joinContainer, { flexDirection }] as ViewStyle

  const box1Style = [
    {
      flex: 1,
      alignItems: isMobile ? 'center' : 'flex-end',
      marginRight: isMobile ? undefined : gap,
      marginBottom: isMobile ? gap : undefined,
      gap: isTablet ? 30 : isPC ? 50 : undefined,
    },
  ] as ViewStyle

  const box2Style = {
    flex: isMobile ? undefined : isTablet ? 1.2 : 1.5,
    alignItems: isMobile ? 'center' : 'flex-start',
    gap: isMobile ? 20 : isTablet ? 20 : 25,
    justifyContent: 'space-around',
  } as ViewStyle

  const buttonContainerStyle = {
    flexDirection: isPC ? 'row' : 'column',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-end',
  } as TextStyle

  const registerButtonStyle = [
    styles.registerButton,
    {
      alignSelf: isMobile ? 'center' : 'flex-start',
      marginBottom: isMobile ? 20 : undefined,
      maxWidth: isTablet ? 200 : isPC ? 234 : undefined,
      maxHeight: isTablet ? 40 : isPC ? 46.81 : undefined,
    },
  ] as TextStyle

  const titleStyle = [
    styles.subTitle,
    { fontSize: fontSizes.subTitle, textAlign: isMobile ? 'center' : 'right' },
    isTablet && { fontSize: 50, lineHeight: 45 },
    isPC && pcTitleStyle,
  ] as TextStyle

  const mainTitleStyle = [
    styles.mainTitle,
    { fontSize: fontSizes.mainTitle },
    isTablet && { fontSize: 80, lineHeight: 70 },
    isPC && pcMainTitleStyle,
  ]
  const descriptionTextStyle = [
    styles.subtitleText,
    {
      fontSize: fontSizes.description,
      paddingRight: isMobile ? 20 : isTablet ? 10 : undefined,
      paddingLeft: isMobile ? 20 : undefined,
      color: color.outerSpace,
      textAlign: isMobile ? 'center' : 'left',
    },
    isTablet && { fontSize: 18, lineHeight: 24 },
    isPC && pcDescriptionStyle,
  ] as TextStyle

  const getStartedTextStyle = [
    styles.getStartedText,
    { fontSize: fontSizes.button },
    isTablet && { fontSize: 20, lineHeight: 18 },
    isPC && pcButtonStyle,
  ]
  const imageStyle = [
    styles.joinImage,
    { resizeMode: isTablet ? 'cover' : 'contain' },
    imageSize,
  ] as ImageStyle

  const redirectToRegisterOrCommunity = () =>
    navigate(accessToken !== undefined ? 'communityMain' : 'signup')

  return (
    <View style={containerStyle} testID="intro-join-section">
      <View style={box1Style}>
        <Text style={titleStyle}>
          {t('landing.oneTimeIntro.readyTo')}
          <Text style={[titleStyle, styles.highlightedText]}>
            {t('landing.oneTimeIntro.join')}
          </Text>
        </Text>
        <Text style={mainTitleStyle}>{t('landing.logoAndName.jan') + '?'}</Text>
      </View>

      <View style={box2Style}>
        <Text style={descriptionTextStyle}>
          {t('landing.oneTimeIntro.readyToJoin.subtitle')}
        </Text>
        <Text style={getStartedTextStyle}>
          {t('landing.oneTimeIntro.readyToJoin.getStartedWithUsNow')}
        </Text>
        <View style={buttonContainerStyle}>
          <TouchableOpacity
            style={registerButtonStyle}
            testID="register-button"
            onPress={redirectToRegisterOrCommunity}
          >
            <Text style={styles.registerButtonText}>
              {t(
                accessToken !== undefined
                  ? 'landing.oneTimeIntro.joinUsNow'
                  : 'auth.register'
              )}
            </Text>
          </TouchableOpacity>
          <Image
            source={require('~/assets/images/landingPage/intro_join_people.png')}
            style={imageStyle}
          />
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  getStartedText: {
    fontWeight: 'bold',
    color: color.outerSpace,
  },
  highlightedText: {
    color: color.primary,
  },
  joinContainer: {
    backgroundColor: color.white,
    maxWidth: 1920,
  },
  joinImage: {
    alignSelf: 'flex-end',
    justifyContent: 'space-evenly',
  },
  mainTitle: {
    fontWeight: 'bold',
    color: color.outerSpace,
  },
  registerButton: {
    backgroundColor: color.primary,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 3,
    alignItems: 'center',
    flex: 1,
  },
  registerButtonText: {
    color: color.white,
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
    color: color.outerSpace,
  },
  subtitleText: {
    textAlign: 'left',
  },
})

export default IntroJoinSection
