import { useRoute } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Footer from '~/components/common/organisms/Footer'
import GetInvolvedSection from '~/components/common/organisms/GetInvolvedSection'
import LandingPageBanner from '~/components/common/organisms/LandingPageBanner'
import SidebarLayout from '~/components/common/organisms/SidebarLayout'
import PairButtons from '~/components/common/organisms/button/PairButtons'
import NotFound from '~/components/common/pages/NotFound'
import { getMenuItemsOfCommunityDetails } from '~/constants/landingPage/Community'
import { getInvolvedFeatures } from '~/constants/landingPage/Involved'
import {
  getSubmenuList,
  NavigationTab,
} from '~/constants/landingPage/Navigation'
import useDevice from '~/hooks/commons/useDevice'

const CommunityIntroduction = () => {
  const { t } = useTranslation()
  const menuItems = getMenuItemsOfCommunityDetails(t)
  const features = getInvolvedFeatures(t)
  const route = useRoute<{
    key: string
    name: string
    params: { tab?: string; selectedSubmenuIndex?: number }
  }>()
  const selectedSubmenuIndex = route.params?.selectedSubmenuIndex
  const tabName = route.params?.tab as NavigationTab
  const submenuList = getSubmenuList(t, tabName)
  const { isPC } = useDevice()

  if (
    selectedSubmenuIndex !== undefined &&
    (selectedSubmenuIndex > submenuList.length || selectedSubmenuIndex < 0)
  ) {
    return <NotFound />
  }

  const renderActionButtons = () => {
    return (
      <PairButtons
        items={[
          `${t('community.information.about')} ${t('landing.logoAndName.jan')}`,
          t('landing.alumniBenefits'),
        ]}
      />
    )
  }

  return (
    <>
      <LandingPageBanner
        title={t('landing.ourCommunity')}
        subTitle={t('landing.ourCommunityDescription')}
        actionButtons={renderActionButtons()}
      />

      {isPC ? (
        <SidebarLayout data={menuItems} />
      ) : (
        <LandingPageBanner
          title={submenuList[selectedSubmenuIndex ?? 0].title}
          subTitle={submenuList[selectedSubmenuIndex ?? 0].content}
          inverted
          isTitleColorPrimary
        />
      )}
      <GetInvolvedSection features={features} />
      <Footer />
    </>
  )
}

export default CommunityIntroduction
