import { useFocusEffect, useRoute } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, useWindowDimensions, View, ViewStyle } from 'react-native'

import CommunityIntroduction from '~/components/common/pages/CommunityIntroduction'
import Find from '~/components/common/pages/Find'
import OneTimeIntro from '~/components/common/pages/OneTimeIntro'
import ProgramsAndEvents from '~/components/common/pages/ProgramsAndEvents'
import color from '~/constants/common/color'
import { setPageTitle } from '~/utils/navigation'

const MAX_WIDTH = 1920

const TopPage = () => {
  const { t } = useTranslation()
  const route = useRoute<{
    key: string
    name: string
    params: { tab?: string }
  }>()

  const tab = route.params?.tab

  const { width } = useWindowDimensions()
  const isWideScreen = width > MAX_WIDTH

  useFocusEffect(() => setPageTitle(t('enjin')))

  const pageComponents: Record<string, JSX.Element> = {
    community: <CommunityIntroduction />,
    program: <ProgramsAndEvents />,
    find: <Find />,
  }

  return (
    <ScrollView
      testID="top-page"
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
    >
      <View style={styles.wideScreenBorder(isWideScreen, width)}>
        {pageComponents[tab || ''] || <OneTimeIntro />}
      </View>
    </ScrollView>
  )
}

const styles = {
  container: {
    maxWidth: MAX_WIDTH,
    maxHeight: 6612,
    alignSelf: 'center',
  } as ViewStyle,
  wideScreenBorder: (isWideScreen: boolean, width: number) =>
    ({
      flex: 1,
      width: isWideScreen ? MAX_WIDTH : width,
      borderRightWidth: isWideScreen ? 1 : undefined,
      borderLeftWidth: isWideScreen ? 1 : undefined,
      borderColor: isWideScreen ? color.black : undefined,
    } as ViewStyle),
}

export default TopPage
