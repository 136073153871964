import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, Image, StyleSheet, TouchableOpacity } from 'react-native'

import color from '~/constants/common/color'
import { FONT_SIZE_MAP, FontSizeKey } from '~/constants/common/font'
import { navigate } from '~/utils/navigation'

type Props = {
  isHideImage?: boolean
  isHideText?: boolean
  inverted?: boolean
  size?: FontSizeKey
}

export default function Logo({
  isHideImage = false,
  isHideText = false,
  inverted = false,
  size = 'medium',
}: Props): JSX.Element {
  const { t } = useTranslation()

  const titleStyle = [
    styles.title,
    { fontSize: FONT_SIZE_MAP[size] },
    inverted && styles.invertTitle,
  ]
  const subtitleStyle = [styles.subtitle, inverted && styles.invertSubtitle]

  return (
    <View style={styles.container} testID="logo">
      {!isHideImage && (
        <TouchableOpacity onPress={() => navigate('topPage')}>
          <Image
            source={require('~/assets/images/jan_logo2x-03.png')}
            style={styles.logo}
            testID="logo-image"
          />
        </TouchableOpacity>
      )}

      {!isHideText ? (
        <View style={styles.titleContainer} testID="content-container">
          <Text style={titleStyle} testID="title">
            {t('landing.logoAndName.jan')}
          </Text>

          <Text style={subtitleStyle} testID="subtitle">
            {t('landing.logoAndName.japanAlumniNetwork')}
          </Text>
        </View>
      ) : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  invertSubtitle: {
    fontSize: 14,
    color: color.white,
    marginTop: 4,
    textAlign: 'center',
  },
  invertTitle: {
    fontWeight: 'bold',
    color: color.white,
    textAlign: 'center',
  },
  logo: {
    width: 150,
    height: 50,
    resizeMode: 'contain',
    marginRight: 10,
  },
  subtitle: {
    color: color.black,
    flexWrap: 'wrap',
  },
  title: {
    fontWeight: 'bold',
    color: color.black,
  },
  titleContainer: {
    flex: 1,
    alignItems: 'flex-start',
  },
})
