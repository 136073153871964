import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CommunityQueryParams,
  CommunityType,
} from '~/api/communities/communities'
import TopTabNavigator, {
  TopTabNavigatorData,
} from '~/components/common/organisms/TopTabNavigator'
import CommunityList from '~/components/community/features/CommunityList'
import HeaderCommunity from '~/components/community/organisms/HeaderCommunity'
import ManagerCommunityMobile from '~/components/community/organisms/ManagerCommunityMobile'
import useDevice from '~/hooks/commons/useDevice'
import useCustomNavigation from '~/hooks/useNavigation'
import { getRouteParams } from '~/utils/navigation'

const MobileTemplate: React.FC = () => {
  const { t } = useTranslation()
  const { isPC } = useDevice()
  const { setQueryParams } = useCustomNavigation()
  const queryParams = getRouteParams<CommunityQueryParams>()

  const topTabNavigatorData: TopTabNavigatorData[] = [
    { label: t('community.mobile-community'), key: 'all' },
    { label: t('community.pages.joined'), key: 'joined' },
    { label: t('community.pages.manage'), key: 'manage' },
  ]

  const [currentCommunityType, setCurrentCommunityType] = useState<
    TopTabNavigatorData | undefined
  >(topTabNavigatorData[0])

  const changeCommunityType = (tab: TopTabNavigatorData): void => {
    setCurrentCommunityType(tab)
    setQueryParams({ type: tab.key as CommunityType })
  }

  useEffect(() => {
    const tabKey = queryParams?.type ? queryParams.type : 'all'
    const selectedTab = topTabNavigatorData.find((item) => item.key === tabKey)
    setCurrentCommunityType(selectedTab)
  }, [queryParams.type])

  return queryParams.type !== 'manage' ? (
    <CommunityList
      params={queryParams}
      header={
        <>
          <HeaderCommunity />
          <TopTabNavigator
            isPC={isPC}
            data={topTabNavigatorData}
            onSelectTab={changeCommunityType}
            selectedItem={currentCommunityType}
          />
        </>
      }
    />
  ) : (
    <ManagerCommunityMobile isPC={isPC} />
  )
}

export default MobileTemplate
