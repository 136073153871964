import React from 'react'
import { View, TextInput, StyleSheet } from 'react-native'
import { InputProps } from 'react-native-elements'

import TitleText from '~/components/common/atoms/TitleText'
import color from '~/constants/common/color'

type Props = {
  title?: string
} & InputProps

const LabeledTextInput = ({ title, ...inputProps }: Props) => {
  return (
    <View style={styles.container} testID="labeled-text-input-container">
      <TitleText testID="labeled-text-input-title">{title}</TitleText>
      <TextInput
        style={styles.input}
        testID="labeled-text-input"
        {...inputProps}
      />
    </View>
  )
}

export default LabeledTextInput

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: 10,
  },
  input: {
    flex: 1,
    borderWidth: 1,
    borderColor: color.borderSeparator,
    borderRadius: 8,
    padding: 10,
    backgroundColor: color.white,
  },
})
