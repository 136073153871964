export enum ColorTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Light = 'light',
}

export default {
  primary: '#2A9D8F',
  primaryVariant: '#02976d',
  primaryLight: '#E6F3F2',
  primarySecondaryLight: '#B8DFDA',
  secondary: '#ff9300',
  secondaryLight: '#fef4e5',
  baseBackground: '#f9f9f9',
  placeholderText: '#828282',
  pressableText: '#4f577d',
  warning: '#C48C41',
  textGray: '#808285',
  menuIcon: '#676767',
  lightGray: '#f1f1f1',
  borderSeparator: '#D8D8D8',
  borderSeparatorDark: '#808285',
  secondLightGray: '#EDEDED',
  white: '#ffffff',
  transparent: 'transparent',
  transparentWhite: 'rgba(255,255,255,.6)',
  black: '#000000',
  gray: '#e2e2e2',
  badgeColor: '#ee7582',
  mask: 'rgba(0,0,0,.5)',
  lightMask: 'rgba(0,0,0,.1)',
  searchInputBackground: '#08ad7e',
  maskWhite: 'rgba(255,255,255,.2)',
  removeButton: '#A4A7A9',
  enjinBlue: '#CEE4F4',
  enjintLightBlue: '#E5F1F9',
  enjinSecondaryLight: '#FEEACF',
  textInputBackground: '#f3f3f3',
  danger: '#df4759',
  unpressableTitleText: '#434343',
  darkTitleText: '#231F20',
  correctAnswer: '#07966D',
  incorrectAnswer: '#EE7582',
  bodyText: '#434343',
  inactive: '#a9a9a9',
  darkBlue: '#ADC0CE',
  darkGray: '#393939',
  hightLightGray: '#535353',
  backgroundNotification: '#f2f2f2',
  serviceImageBackground: '#ecf8fe',
  selected: '#C4C3C2',
  active: '#3CF79F',
  chineseSilver: '#cccccc',
  americanSilver: '#CECECE',
  pewterBlue: '#8CBFB8',
  polishedPine: '#53AC9F',
  illuminatingEmerald: '#249384',
  steelGray: '#5D5D5D',
  outerSpace: '#555555',
  sliverGray: '#747474',
}
