import React from 'react'
import Svg, {
  LinearGradient,
  Stop,
  Circle,
  Path,
  SvgProps,
} from 'react-native-svg'

const FacebookIcon = (props?: SvgProps): JSX.Element => {
  return (
    <Svg
      clipRule="evenodd"
      fillRule="evenodd"
      height={2500}
      viewBox="17006.82 17511.1 8157.800000000003 8157.800000000003"
      width={1618}
      testID="facebook-icon"
      {...props}
    >
      <LinearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1={21085.72}
        x2={21085.72}
        y1={18249.39}
        y2={25150.62}
      >
        <Stop offset={0} stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" />
      </LinearGradient>
      <Circle cx={21085.72} cy={21590} fill="#fff" r={4078.9} />
      <Path
        d="M21085.72 18309.17c1811.95 0 3280.83 1468.88 3280.83 3280.83s-1468.88 3280.83-3280.83 3280.83-3280.83-1468.88-3280.83-3280.83 1468.88-3280.83 3280.83-3280.83z"
        fill="url(#a)"
      />
      <Path
        d="M21512.01 24843.29v-2534.17h714.43l94.7-891.91h-809.13l1.2-446.44c0-232.63 22.1-357.22 356.24-357.22h446.68v-892.06h-714.59c-858.35 0-1160.42 432.65-1160.42 1160.34v535.45h-535.07v891.99h535.07v2498.09c208.45 41.53 423.95 63.47 644.6 63.47a3310.9 3310.9 0 00426.29-27.54z"
        fill="#2A9D8F"
        fillRule="nonzero"
      />
    </Svg>
  )
}

export default FacebookIcon
